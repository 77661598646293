import React from "react";
import { useEffect, useState, useRef } from "react";
import { Table, Tag, DatePicker, Col, Row, Button, Form, Modal, Divider, Input } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { LeftOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import styles from "./tablebooking.module.css";
import BookingCreate from "../Reserva/BookingCreate";
import BookingInfo from "../Reserva/BookingInfo";
import Loader from "../Loader/Loader";
import { Lock } from "@mui/icons-material";

import apiManager from "../../api/apiManager";
import "./tablebooking.css";

//confirm dialog
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

export default function TableInvoiceIncomplete(props) {

  const dateFormat = 'DD-MM-YYYY';
  const formRef = useRef();

  useEffect(() => {
    form.setFieldValue('datepicker', null)
    getBookings(JSON.parse(localStorage.getItem("activeCenter")).id)
    setChannelList([])
    apiManager.getChannels(JSON.parse(localStorage.getItem("activeCenter")).id)
    .then((res) => {
      res.data.data.map((channel) => {
        setChannelList(current => [...current, {
          'text': channel.title,
          'value': channel.title
        }])
      })
    })
    apiManager.isAdmin(localStorage.getItem("token"))
    .then((res) => {
      setIsAdmin(res.data.data[0].user_is_admin)
    })
  }, [])

  const [dataTableSimple, setDataTableSimple] = useState([])
  const [dataTableBase, setDataTableBase] = useState([])
  const [dateValue, setDateValue] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalBookingOpen, setIsModalBookingOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [paginationItems, setPaginationItems] = useState(50)
  const [channelList, setChannelList] = useState([])
  const [bookingIdSelected, setBookingIdSelected] = useState({})
  const [isAdmin, setIsAdmin] = useState(false)
  const [form] = Form.useForm()
  const [formSearch] = Form.useForm()

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getBookings = (centerId) => {
    setIsLoading(true)
    apiManager.pendingFacturation(null, null, null, centerId)
    .then((res) => {
      let result = res.data.data
      setDataTableSimple(result)
      setDataTableBase(result)
      setIsLoading(false)
      if (paginationItems !== 50 && paginationItems !== 100 && paginationItems !== 500) {
        if (paginationItems !== result.length) {
          setPaginationItems(50)
          setPaginationItems(result.length)
        }
      }
      if (form.getFieldValue('datepicker') !== null) {
        let fecha = form.getFieldValue('datepicker')
        changeTableDay(null, fecha.format('DD-MM-YYYY'))
      }
    })
  }

  const changeTableDay = (newDate, newDateFormated) => {
    if (newDateFormated !== "") {
      let tableData = dataTableBase
      const filterResult = tableData.filter(
        (item) =>
          item.start_date === newDateFormated
      )
      setDataTableSimple(filterResult)
    } else {
      setDataTableSimple(dataTableBase)
    }
  }

  const columns = [
    {
      title: 'Reserva',
      dataIndex: 'booking_id',
    },
    {
      title: 'Prénom et nom',
      dataIndex: 'client'
    },
    {
      title: 'Email',
      dataIndex: 'client_email',
      render: (text) => `${text ? text : "-"}`,
    },
    {
      title: 'Total réservation',
      dataIndex: 'total_price',
      align: 'right',
      render: (text) => <span>{ Number(text).toFixed(2).replace(/[.,]00$/, "") + "€" }</span>,
      sorter: (a, b) => Number(a.total_price).toFixed(2) - Number(b.total_price).toFixed(2),
    },
    {
      title: 'Solde restant',
      dataIndex: 'total_pending',
      align: 'right',
      render: (text) => <span>{ Number(text).toFixed(2).replace(/[.,]00$/, "") + "€" }</span>,
      sorter: (a, b) => Number(a.total_pending).toFixed(2) - Number(b.total_pending).toFixed(2),
    },
    {
      title: 'Total activités vendues',
      dataIndex: 'num_act',
      align: 'right',
      render: (text) => `${text !== "l´activité de réservation est manquante dans la réservation" ? text : 0}`,
      sorter: (a, b) => a.num_act - b.num_act
    },
    {
      title: 'Date entrée',
      dataIndex: 'start_date',
    },
    {
      title: 'Date départ',
      dataIndex: 'end_date',
    },
    {
      title: 'Date réservation',
      dataIndex: 'created_date',
    },
    {
      title: 'Partenaires',
      dataIndex: 'channel',
      filters: channelList,
      onFilter: (value, record) => record.channel.indexOf(value) === 0,
    },
    {
      title: 'Statut',
      dataIndex: 'status',
      filters: [
        {
          text: 'Payer',
          value: 'Pagado',
        },
        {
          text: 'Partiellement payer',
          value: 'Parcial',
        },
        {
          text: 'Non payer',
          value: 'No pagado',
        },
        {
          text: 'â rembourser',
          value: 'A devolver',
        },
        {
          text: 'Bloquée',
          value: 'Bloqueada',
        }
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (value) => {
        let color = '';
        if (value === 'Pagado') {
          color = 'green';
        } else if (value === 'Parcial') {
          color = 'orange';
        } else if (value === 'No pagado') {
          color = 'red';
        } else if (value === 'A devolver') {
          color = 'blue';
        }
        return (
          <Tag color={color} className={value === 'Bloqueada' ? 'bloqued' : ''}>
            {
              value === 'Bloqueada' ?
                <Lock style={{
                  fill: '#515151',
                  fontSize: 15,
                  marginTop: -3,
                  marginRight: 3
                }} /> : <></>
            }
            {value}
          </Tag>
        );
      }
    }
  ]

  const summaryTable = (pageData) => {
    if (dataTableSimple.length > 0 && isAdmin) {
      let totalBookings = 0;
      let totalPrice = 0;
      let totalPending = 0

      pageData.forEach(({ total_price, num_act, total_pending }) => {
        if (isNaN(num_act)) {
          totalBookings += 0;
        } else {
          totalBookings += num_act;
        }
        totalPrice = totalPrice + Number(total_price);
        totalPending = totalPending + Number(total_pending);
      });
      
      return (
        <>
          <Table.Summary.Row>
            <Table.Summary.Cell colSpan={3}><b>TOTAL</b></Table.Summary.Cell>
            <Table.Summary.Cell align="right"><b>{ Number(totalPrice).toFixed(2).replace(/[.,]00$/, "") + "€" }</b></Table.Summary.Cell>
            <Table.Summary.Cell align="right"><b>{ Number(totalPending).toFixed(2).replace(/[.,]00$/, "") + "€" }</b></Table.Summary.Cell>
            <Table.Summary.Cell align="right"><b>{ totalBookings }</b></Table.Summary.Cell>
            <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
          </Table.Summary.Row>
        </>
      )
    }
  }

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
    if (pagination.pageSize !== paginationItems) {
      setPaginationItems(pagination.pageSize)
    }
  };

  const confirmCancelResume = (texto) => {
    confirmAlert({
      title: 'Vous êtes sur de vouloir ' + texto + '?',
      message: 'Vous perdrez toutes les données sur les activités.',
      buttons: [
        {
          label: 'Retour',
          onClick: () => closeModal(),
          className: 'close-btn',
        },
        {
          label: 'Annuler',
          className: 'cancel-btn',
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-confirm-suprimir"
    })
  }

  const closeFinishBooking = (date, channel, id) => {
    closeModal()
    let info = {
      id: id,
      channel_id: channel
    }
    let fechaNueva = moment(date.split("-").reverse().join("-"));
    setDateValue(fechaNueva.format('DD-MM-YYYY'))
    getBookings(JSON.parse(localStorage.getItem("activeCenter")).id)
    setBookingIdSelected(info)
    setIsModalBookingOpen(true)
    formSearch.setFieldValue("text", "")
  }

  const prevDay = (currentDay) => {
    let fechaNueva = dayjs(currentDay).add(-1, 'day')
    setDateValue(fechaNueva.format('DD-MM-YYYY'))
    form.setFieldValue('datepicker', fechaNueva)
    changeTableDay(null, fechaNueva.format('DD-MM-YYYY'))
  }

  const nextDay = (currentDay) => {
    let fechaNueva = dayjs(currentDay).add(1, 'day')
    setDateValue(fechaNueva.format('DD-MM-YYYY'))
    form.setFieldValue('datepicker', fechaNueva)
    changeTableDay(null, fechaNueva.format('DD-MM-YYYY'))
  }

  const openBookingInfo = (info, rowIndex, event) => {
    setBookingIdSelected(info)
    setIsModalBookingOpen(true)
  }

  const closeBookingInfoModal = () => {
    getBookings(JSON.parse(localStorage.getItem("activeCenter")).id)
    //changeTableDay(null, dateValue)
    setIsModalBookingOpen(false)
  }

  const searchOnTable = (text) => {
    console.log(dataTableBase)
    if (text.text !== undefined && text.text !== "") {
      let tableData = dataTableBase
      const filterResult = tableData.filter(
        (item) =>
          item.client
            .toLowerCase().includes(text.text.toLowerCase()) ||
          item.booking_id
            .toLowerCase().includes(text.text.toLowerCase()) ||
          item.channel.toLowerCase().includes(text.text.toLowerCase()) ||
          item.client_email.toLowerCase().includes(text.text.toLowerCase())
      )
      setDataTableSimple(filterResult)
    } else {
      setDataTableSimple(dataTableBase)
    }
  }

  return (
    <>
      <Loader controller={isLoading} />
      <Modal
        maskClosable={false}
        onCancel={() => confirmCancelResume('cerrar la ventana')}
        centered={true}
        open={isModalOpen}
        width={1140}
        destroyOnClose={true}
        cancelText="Annuler réservation"
        footer={null}
      >
        <BookingCreate
          closeFinishBooking={closeFinishBooking}
        />
        <Divider />
        <Row justify="end">
          <Col>
            <Button
              onClick={() => confirmCancelResume('cancelar la reserva')}
              className="basic-btn"
            >Annuler réservation</Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        maskClosable={false}
        onCancel={() => closeBookingInfoModal()}
        centered={true}
        open={isModalBookingOpen}
        width={1000}
        destroyOnClose={true}
        cancelText="Retour"
        footer={null}
      >
        <BookingInfo
          bookingInfoData={bookingIdSelected}
          bookingClose={closeBookingInfoModal}
          selectedDate={dateValue}
        />
      </Modal>
      <Row
        justify={'space-between'}
        className={styles.header}
      >
        <Col span={8} style={{display: "flex", justifyContent: "left", alignItems: "center"}}>
        </Col>
        <Col span={8} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Form
            form={form}
            ref={formRef}
            style={{display: "flex", gap: 10}}
          >
            <Button
              onClick={() => prevDay(form.getFieldValue('datepicker'))}
              disabled={form.getFieldValue('datepicker') === null}
            ><LeftOutlined /></Button>
            <Form.Item
              name="datepicker"
              style={{marginBottom: 0}}
            >
              <DatePicker
                format={dateFormat}
                className="datapickerElement"
                value={dateValue}
                style={{width: 160}}
                onChange={(newDate, newDateFormated) => changeTableDay(newDate, newDateFormated)}
              />
            </Form.Item>
            <Button
              onClick={() => nextDay(form.getFieldValue('datepicker'))} style={{marginLeft: "-5px"}}
              disabled={form.getFieldValue('datepicker') === null}
            ><RightOutlined /></Button>
          </Form>
        </Col>
        <Col span={8} style={{display: "flex", justifyContent: "right", alignItems: "center"}}>
          <Form
            className="formSerachBooking"
            form={formSearch}
            onFinish={(values) => searchOnTable(values)}
            style={{marginRight: 20}}
          >
            <Form.Item
              name="text"
              style={{marginBottom: 0}}
            >
              <Input
                placeholder="Recherche..."
              />
            </Form.Item>
            <Form.Item
              style={{marginBottom: 0}}
            >
              <Button
                className="iconCircle"
                htmlType="submit"
              ><SearchOutlined className="white" /></Button>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            shape="round"
            size='large'
            className={styles.button}
            onClick={() => showModal()}
            style={{marginRight: 6}}
          >
            <p><span>+</span>Réservation</p>
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={dataTableSimple} 
        bordered
        onChange={onChange}
        className="mainTable"
        pagination={
          {
            total: dataTableSimple.length,
            pageSize: paginationItems,
            //defaultPageSize: 50,
            showSizeChanger: true,
            onChange: (e) => console.log(e),
            pageSizeOptions: ['50', '100', '500', dataTableSimple.length],
            locale: { items_per_page: ""},
            showTotal: (total) => `${total} ${total < 2 ? "activité" : "activités"} aujourd´hui`,
          }
        }
        summary={pageData => summaryTable(pageData)}
        onRow={(record, rowIndex) =>
          true ?
            { onClick: event => openBookingInfo(record, rowIndex, event) }
          : ""
        }
      />
    </>
  )
}