import React, { useEffect, useState, useRef } from "react"
import { Modal, Row, Col, Table, Divider, Tooltip, Button } from "antd"
import { Print } from "@mui/icons-material"
import styled from "@emotion/styled"
import ReactToPrint from "react-to-print"
import "./simpleTable.css"
import "./printDoc.css"

export default function DeliveryModal(props) {

  useEffect(() => {
    //console.log(props.delivery)
    /* if (props.delivery.bookings !== undefined) {
      setDataTable([])
      for(let booking of props.delivery.bookings) {
        setDataTable(current => [...current, {
          'start_date': booking.start_date,
          'identifier': booking.identifier,
          'internal_ref': booking.internal_ref,

          'value': delivery.sales_channel
        }])
      }
      setDataTable([])
    } */
  }, [props])

  const [dataTable, setDataTable] = useState([])
  const [totalSum, setTotalSum] = useState("0")

  let componentRef = useRef()

  const loadDataTable = (data) => {
    let dataTable = []
    let values = data.booking_activity
    for(let act of values) {
      dataTable.push({
        name_act: act.name,
        num_pax: act.num_pax,
        total_iva: act.total_iva,
        total_price: act.total_price,
      })
    }
    return dataTable
  }

  const loadDataTableTop = (data) => {
    let dataTable = [{
      start_date: data.start_date,
      identifier: data.identifier,
      name_act: data.client_info,
      internal_ref: data.internal_ref,
    }]
    return dataTable
  }

  const loadDataTableIVA = (data) => {
    let dataTable = [
      {
        title: "Base imponible",
        first: data.base_imponible_iva,
        second: data.base_imponible_total,
      },
      {
        title: `Importe ${ data.vat_title }`,
        first: data.import_iva,
        second: data.total_iva,
      }
    ]
    //let sum = dataTable.reduce((accumulator, object) => { return accumulator + Number(object.second) }, 0)
    return dataTable
  }

  const columns = [
    {
      title: 'Date de début',
      dataIndex: 'start_date',
      key: 'start_date',
      width: 100,
    },
    {
      title: 'Num. réservation',
      dataIndex: 'identifier',
      key: 'identifier',
      width: 100,
    },
    {
      title: 'Prénom et nom',
      dataIndex: 'name_act',
      key: 'name_act',
    },
    {
      title: 'Réf. interne',
      dataIndex: 'internal_ref',
      key: 'internal_ref',
      width: 150,
    },
    {
      title: 'Participants',
      dataIndex: 'num_pax',
      key: 'num_pax',
      align: 'center',
      width: 80,
    },
    {
      title: 'Taux de TVA',
      dataIndex: 'total_iva',
      key: 'total_iva',
      align: 'right',
      width: 100,
      render: (text) => text === undefined ? "" : text + "%"
    },
    {
      title: 'Total TVA incluse',
      dataIndex: 'total_price',
      key: 'total_price',
      align: 'right',
      width: 100,
      render: (text) => text === undefined ? "" : Number(text).toFixed(2) + "€"
    }
  ]

  const columnsIVA = [
    {
      title: '',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '',
      dataIndex: 'first',
      key: 'first',
      align: 'right',
      width: 100,
      render: (text) => text === undefined ? "" : text + "%"
    },
    {
      title: '',
      dataIndex: 'second',
      key: 'second',
      align: 'right',
      width: 100,
      render: (text) => text === undefined ? "" : Number(text).toFixed(2) + "€"
    }
  ]

  const Footer = styled.div`
    margin-top: 20px;
    color: #b1b1b1;
    * {
      color: #b1b1b1;
    }
  `;

  function importAll(r) {
    let images = {}
    r.keys().map((item, index) => { images[item.replace('./', '').split('.').shift()] = r(item); })
    return images
  }

  const images = importAll(require.context('../Images', false, /\.(png|jpe?g|svg)$/))

  const headerFiscal = () => {
    let header = props.delivery.fiscal_company.header_info !== undefined ? props.delivery.fiscal_company.header_info : ""
    header = header.replace(/\\n/g,"").replace(/\\r/g,"").replace(/\\t/g,"")
    return (
      <div className="" dangerouslySetInnerHTML={{__html: header}}></div>
    )
  }

  const footerFiscal = () => {
    let footer = props.delivery.fiscal_company.footer_info !== undefined ? props.delivery.fiscal_company.footer_info : ""
    footer = footer.replace(/\\n/g,"").replace(/\\r/g,"").replace(/\\t/g,"")
    return (
      <Footer
        dangerouslySetInnerHTML={{__html: footer}}
      ></Footer>
    )
  }

  return (
    
    <Modal
      maskClosable={false}
      onCancel={() => props.closeInfoDeliveryFunc()}
      centered={true}
      open={props.open}
      width={1000}
      destroyOnClose={true}
      cancelText="Retour"
      footer={null}
    >
      {
        props.delivery.delivery_id !== undefined ?
          <>
            <ReactToPrint
              trigger={() => <Tooltip title="Imprimir documento">
                <Button style={{position: 'absolute', top: 8, left: 8, height: 'auto'}}><Print style={{fontSize: 30}} /></Button>
              </Tooltip>}
              content={() => componentRef}
              documentTitle={`delivery-${props.delivery.delivery_id !== undefined ? props.delivery.delivery_id : 0}`}              
            />
            {/* <DocToPrint ref={(el) => (componentRef = el)} /> */}
            <div ref={(el) => (componentRef = el)}>
              <Row style={{padding: 30, columns: 1}}>
                <Col span={11}>
                  {
                    props.delivery.fiscal_company.logo_url !== "" ?
                     <img src={props.delivery.fiscal_company.logo_url} alt={props.delivery.fiscal_company.social_reason} style={{maxHeight: 55, marginBottom: 15}} />
                    : <></>
                  }
                  {/* <img src={images.logoBono} alt="logo" style={{maxHeight: 55, marginBottom: 15}} /> */}
                </Col>
                <Col span={11} offset={2}></Col>
                <Col span={14}>
                  <p>{props.delivery.fiscal_company.social_reason}</p>
                  <p>{props.delivery.fiscal_company.fiscal_nif}</p>
                  {headerFiscal()}
                </Col>
                <Col span={8} offset={2} style={{textAlign: 'right', backgroundColor: '#dbf0fa', padding: 15}}>
                  <p style={{fontSize: 15}}>{props.delivery.client_company.social_reason}</p>
                  <p style={{fontSize: 15}}>
                    {
                      props.delivery.client_company.fiscal_address !== undefined && props.delivery.client_company.fiscal_address !== "" ?
                        props.delivery.client_company.fiscal_address + ", "
                      : null
                    }
                    {
                      props.delivery.client_company.cp !== undefined && props.delivery.client_company.cp !== "" ?
                        props.delivery.client_company.cp + " "
                      : null
                    }
                    {
                      props.delivery.client_company.country !== undefined && props.delivery.client_company.country !== "" ?
                        props.delivery.client_company.country
                      : null
                    }
                    {/* {`${props.delivery.client_company.fiscal_address}, ${props.delivery.client_company.cp} ${props.delivery.client_company.country}`} */}
                  </p>
                  <p style={{fontSize: 15}}>{props.delivery.client_company.dni}</p>
                </Col>
                <Col span={24} style={{border: '1px solid #b0daed', padding: '5px 10px', marginTop: 5}}>
                  <p><b>Albarán {props.delivery.delivery_id}</b> - Date: {props.delivery.delivery_date} - Periodo: {props.delivery.delivery_period}</p>
                </Col>
                <Col span={24}>
                  <Table
                    style={{breakInside: 'avoid', marginTop: 5}}
                    columns={columns}
                    bordered
                    pagination={false}
                    className="mainTable simpleTable noBody colored"
                    rowKey={record => record.identifier}
                  />
                  {
                    props.delivery.bookings.map(booking => {
                      return (
                        <>
                          {
                            booking.booking_activity.length > 0 ?
                              <>
                                <Table
                                  showHeader={false}
                                  columns={columns}
                                  dataSource={loadDataTableTop(booking)}
                                  bordered
                                  pagination={false}
                                  className="mainTable simpleTable coloredTwo"
                                  rowKey={record => record.identifier}
                                  style={{breakInside: 'avoid'}}
                                />
                                <Table
                                  showHeader={false}
                                  columns={columns}
                                  dataSource={loadDataTable(booking)}
                                  bordered
                                  pagination={false}
                                  className="mainTable simpleTable"
                                  rowKey={record => record.identifier}
                                  style={{breakInside: 'avoid'}}
                                />
                              </>
                            : <></>
                          }
                        </>
                      )
                    })
                  }
                  <Row>
                    <Col span={9} offset={15}>
                      {
                        props.delivery.iva_information.map(iva => {
                          return (
                            <Table
                              style={{marginTop: 20}}
                              showHeader={false}
                              columns={columnsIVA}
                              dataSource={loadDataTableIVA(iva)}
                              bordered
                              pagination={false}
                              className="mainTable simpleTable colored"
                              rowKey={record => record.total_iva}
                            />
                          )
                        })
                      }
                      <h3 style={{display: 'flex', justifyContent: 'space-between', marginTop: 20}}>
                        IMPORTE TOTAL: <b style={{fontSize: '2rem'}}>{ Number(props.delivery.total_price).toFixed(2) + "€" }</b>
                      </h3>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  { footerFiscal() }
                </Col>
              </Row>
            </div>
          </>
        : <></>
      }
    </Modal>
  )
}