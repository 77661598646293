import React from "react";
import { useState, useEffect } from "react";
import { Form, Row, Col, Select, InputNumber, Button, Modal, notification, Divider, Tag, Tooltip } from "antd";
import { CircleSpinnerOverlay } from "react-spinner-overlay";
import { UserOutlined, PlusCircleFilled, MinusCircleFilled, UndoOutlined, FormOutlined, DeleteOutlined, ReloadOutlined } from '@ant-design/icons';
import { AddCircle, Cancel } from "@mui/icons-material";
import apiManager from "../../api/apiManager";

//confirm dialog
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

import "./activityform.css";

export default function ActivityForm(props) {

  //console.log(props)

  const [optionsFamilies, setOptionsFamilies] = useState(props.data !== undefined ? props.data.optionsFamilies : [])
  const [optionsActivities, setOptionsActivities] = useState(props.data !== undefined ? props.data.optionsActivities : [])
  const [optionsRates, setOptionsRates] = useState(props.data !== undefined ? props.data.optionsRates : [])
  const [familiesArray, setFamiliesArray] = useState(props.data !== undefined ? props.data.familiesArray : [])
  const [isLoading, setIsLoading] = useState(false)
  //const [maxPax, setMaxPax] = useState(0)
  //const [listActivities, setListActivities] = useState({})
  const [finishRates, setFinishRates] = useState(props.data !== undefined ? props.data.finishRates : [])
  const [optionsTruns, setOptionsTurns] = useState(props.data !== undefined ? props.data.optionsTruns : [])
  const [selectedTurn, setSelectedTurn] = useState(props.data !== undefined ? props.data.selectedTurn : null)
  const [optionsResources, setOptionsResources] = useState(props.data !== undefined ? props.data.optionsResources : [])
  const [selectedResources, setSelectedResources] = useState(props.data !== undefined ? props.data.selectedResources : [])
  /* */
  const [rateModal, setRateModal] = useState(false)
  const [persons, setPersons] = useState(props.data !== undefined ? props.data.persons : 1)
  const [avPersons, setAvPersons] = useState(props.data !== undefined ? 0 : 1)
  const [maxPersons, setMaxPersons] = useState(props.data !== undefined ? props.data.maxPersons : 4)
  const [units, setUnits] = useState(props.data !== undefined ? props.data.units : 1)
  const [resumeRate, setResumeRate] = useState(props.data !== undefined ? props.data.resumeRate : {})
  const [modifyRate, setModifyRate] = useState(props.data !== undefined ? props.data.modifyRate : {operator: 'minus', value: 0, symbol: 'sum'})
  const [ratePrice, setRatePrice] = useState(props.data !== undefined ? props.data.ratePrice : 0)
  const [ratePricePvP, setRatePricePvP] = useState(props.data !== undefined ? props.data.ratePricePvP : 0)
  const [discountVisibility, setDiscountVisibility] = useState(false)
  const [discountOptions, setDiscountOption] = useState([])
  const [discountSelected, setDiscountSelected] = useState(null)
  /* */
  const [editRateIndex, setEditRateIndex] = useState(-1)
  const [editRateBloqued, setEditRateBloqued] = useState(false)
  const [editRateSelect, setEditRateSelect] = useState([])
  const [turnErrorMsg, setTurnErrorMsg] = useState("")
  const [byUnitMode, setByUnitMode] = useState(props.data !== undefined ? props.data.byUnitMode : true)
  const [notByUnitResorce, SetNotByUnitResorce] = useState(false)
  const [selectResourceNotUnit, setSelectResourceNotUnit] = useState(0)
  const [selectedResourcesNoUnit, setSelectedResourcesNoUnit] = useState(props.data !== undefined ? props.data.selectedResources : [])
  const [listResourcesKey, setListResourcesKey] = useState(0)
  const [reloadTurnDisable, setReloadTurnDisable] = useState(false)
  const [maxUnits, setMaxUnits] = useState(props.data !== undefined ? props.data.maxUnits : 0)
  const [maxUnitsDefault, setMaxUnitsDefault] = useState(props.data !== undefined ? props.data.maxUnits : 0)
  const [form] = Form.useForm()

  const [activitiesArray, setActivitiesArray] = useState(props.data !== undefined ? props.data.activitiesArray : [])
  const [ratesArray, setRatesArray] = useState([])
  const [isB2B, setIsB2B] = useState(false)

  const { Option } = Select;

  useEffect(() => {
    apiManager.getListPromotions()
    .then((res) => {
      let promotionsResult = res.data.data
      setDiscountOption([])
      promotionsResult.map((promotion) => {
        setDiscountOption(current => [...current, {
          'value': promotion.id,
          'label': promotion.name,
          'default': promotion.default
        }])
      })
    })
    if (props.data === undefined) {
      setIsLoading(true)
      let userB2B = props.userData !== undefined ? props.userData.is_b2b : undefined
      let userToken = props.userData !== undefined ? props.userData.user_token : undefined
      //apiManager.getTarifasList(props.selectedChannel, JSON.parse(localStorage.getItem("activeCenter")).id, props.selectedDay)
      apiManager.getTarifasListSimple(props.selectedChannel, JSON.parse(localStorage.getItem("activeCenter")).id, userB2B, userToken)
      .then((res) => {
        let activitiesList = res.data.data.sort(function(a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        //console.log(activitiesList)
        setFamiliesArray(activitiesList)
        activitiesList.map((activity) => {
          setOptionsFamilies(current => [...current, {
            'value': activity.id,
            'label': activity.name
          }])
        })
        setIsLoading(false)
      })
    } else {
      form.setFieldValue("activityFamily", props.data.formValues.activityFamily)
      form.setFieldValue("activity", props.data.formValues.activity)
      form.setFieldValue("turns", props.data.formValues.turns)
      form.setFieldValue("resources", props.data.formValues.resources)
      //console.log(props.data)
    }
    apiManager.isB2B()
    .then((res) => {
      let result = res.data.data[0]
      setIsB2B(result.user_is_b2_b)
    })
  }, [])

  useEffect(() => {
    setDiscountSelected(discountOptions.length > 0 ? (discountOptions.some(o => o.default === true) ? discountOptions.find(o => o.default === true).value : null) : null)
  }, [discountOptions])

  const loadActivities = (id) => {
    setOptionsActivities([])
    setPersons(1)
    setAvPersons(1)
    setOptionsRates([])
    form.setFieldValue('activity', null)
    //setMaxPax(0)
    //form.setFieldValue('activity', undefined)
    //form.setFieldValue('rates', undefined)
    for (var i = 0; i < familiesArray.length; i++) {
      if (familiesArray[i].id === id) {
        setActivitiesArray(familiesArray[i].children)
        updateActivitiesOptions(familiesArray[i].children)
        break;
      }
    }
  }

  const updateActivitiesOptions = (array) => {
    array.map((activity) => {
      setOptionsActivities(current => [...current, {
        'value': activity.id,
        'label': activity.name
      }])
    })
  }

  const loadRates = (id) => {
    for (var i = 0; i < activitiesArray.length; i++) {
      if (activitiesArray[i].id === id) {
        setByUnitMode(activitiesArray[i].by_unit !== undefined ? activitiesArray[i].by_unit : true)
        setRatesArray(activitiesArray[i].children)
        setMaxPersons(activitiesArray[i].max_pax_into_activity)
        updateRatesOptions(activitiesArray[i].children)
        reloadTurns(true)
        //updateTurnsOptions(activitiesArray[i].turns)
        break;
      }
    }
  }

  const updateRatesOptions = (array) => {
    setOptionsRates([])
    array.map((rate) => {
      setOptionsRates(current => [...current, {
        'value': rate.id,
        'label': `${rate.name} - ${!isB2B ? rate.price : rate.pvp_price}€`,
        'name': rate.name,
        'price': rate.price,
        'pvp_price': rate.pvp_price,
        'pax': rate.max_pax,
        'ratio_resources': rate.ratio_resources,
        'need_resources': rate.need_resources
      }])
    })
  }

  const updateTurnsOptions = (array) => {
    setOptionsTurns([])
    //console.log(array)
    array.map((turn) => {
      setOptionsTurns(current => [...current, {
        'value': turn.id,
        'label': turn.schedule
      }])
    })
  }

  const incressPerson = () => {
    //let num = form.getFieldValue('persons')
    if (persons + 1 <= maxPersons) {
      setPersons(current => current + 1)
      setAvPersons(current => current + 1)
    } else {
      notification.warning({
        key: 'warning',
        placement: 'bottom',
        duration: 0,
        message: `Le nombre de personnes pour cette activité ne peut être supérieur à ${maxPersons}`
      })
    }
    //form.setFieldValue('persons', num + 1)
  }

  const decressPerson = () => {
    let num = persons
    if (num - 1 > 0) {
      setPersons(current => current - 1)
      setAvPersons(current => current - 1)
      //form.setFieldValue('persons', num - 1)
    } else {
      notification.warning({
        key: 'warning',
        placement: 'bottom',
        duration: 0,
        message: 'Le nombre de personnes par activité ne peut être inférieur à 1'
      })
    }
  }

  const incressUnit = () => {
    //let num = form.getFieldValue('persons')
    setUnits(current => current + 1)
    let unitsSum = units + 1
    calculateRateTotalPrice(resumeRate.price, unitsSum, modifyRate.value, modifyRate.operator, modifyRate.symbol, resumeRate.pvp_price)
  }

  const decressUnit = () => {
    let num = units
    if (num - 1 > 0) {
      setUnits(current => current - 1)
      let unitsMin = units - 1
      calculateRateTotalPrice(resumeRate.price, unitsMin, modifyRate.value, modifyRate.operator, modifyRate.symbol, resumeRate.pvp_price)
    } else {
      notification.warning({
        key: 'warning',
        placement: 'bottom',
        duration: 0,
        message: 'Le nombre d´unités ne peut être inférieur à 1'
      })
    }
  }

  const selectRate = (e) => {
    //console.log(e)
    //let selectedFamily = familiesArray.find(({id}) => id === form.getFieldValue('activityFamily'))
    //let selectedActivity = selectedFamily.children.find(({id}) => id === form.getFieldValue('activity'))
    let selectedRate = optionsRates.find(({value}) => value === e)
    //console.log(selectedRate)
    //console.log(resumeRate.value)
    setResumeRate(selectedRate)
    calculateRateTotalPrice(selectedRate.price, units, modifyRate.value, modifyRate.operator, modifyRate.symbol, selectedRate.pvp_price)
  }

  const personsValueChange = (newValue) => {
    //console.log(newValue)
    if (newValue < persons) {
      if (newValue < 1) {
        setPersons(1)
        //setAvPersons(1)
        //notification.destroy()
        notification.warning({
          key: 'warning',
          placement: 'bottom',
          duration: 0,
          message: 'Le nombre de personnes par activité ne peut être inférieur à 1'
        })
      } else {
        setPersons(newValue)
        //setAvPersons(newValue)
      }
    } else {
      if (newValue > maxPersons) {
        setPersons(maxPersons)
        //setAvPersons(maxPersons)
        //console.log(notification)
        //notification.destroy()
        notification.warning({
          key: 'warning',
          placement: 'bottom',
          duration: 0,
          message: `Le nombre de personnes pour cette activité ne peut être supérieur à ${maxPersons}`
        })
      } else {
        setPersons(newValue)
        //setAvPersons(newValue)
      }
    }
  }

  const unitsValueChange = (newValue) => {
    //console.log(newValue)
    if (newValue < units) {
      if (newValue < 1) {
        setUnits(1)
        calculateRateTotalPrice(resumeRate.price, 1, modifyRate.value, modifyRate.operator, modifyRate.symbol, resumeRate.pvp_price)
        notification.warning({
          key: 'warning',
          placement: 'bottom',
          duration: 0,
          message: 'Le nombre d´unités ne peut être inférieur à 1'
        })
      } else {
        setUnits(newValue)
        calculateRateTotalPrice(resumeRate.price, newValue, modifyRate.value, modifyRate.operator, modifyRate.symbol, resumeRate.pvp_price)
      }
    } else {
      setUnits(newValue)
      calculateRateTotalPrice(resumeRate.price, newValue, modifyRate.value, modifyRate.operator, modifyRate.symbol, resumeRate.pvp_price)
    }
  }

  const selectBefore = (
    <Select
      value={modifyRate.operator}
      style={{
        width: 50,
      }}
      onChange={(e) => modRatePrice(e, 'operator')}
    >
      <Option value="minus">-</Option>
      <Option value="add">+</Option>
    </Select>
  );
  const selectAfter = (
    <Select
      value={modifyRate.symbol}
      style={{
        width: 50,
      }}
      onChange={(e) => modRatePrice(e, 'symbol')}
    >
      <Option value="sum">€</Option>
      <Option value="percent">%</Option>
    </Select>
  );

  const modRatePrice = (value, type) => {
    if (type === 'input' && value !== 0) {
      setDiscountVisibility(true)
    } else if (type === 'input' && value === 0) {
      setDiscountVisibility(false)
    }
    var rateValue = 0
    if (type === 'operator') {
      calculateRateTotalPrice(resumeRate.price, units, modifyRate.value, value, modifyRate.symbol)
      setModifyRate(current => ({
        operator: value,
        value: current.value,
        symbol: current.symbol
      }))
    } else if (type === 'symbol') {
      calculateRateTotalPrice(resumeRate.price, units, modifyRate.value, modifyRate.operator, value)
      setModifyRate(current => ({
        operator: current.operator,
        value: current.value,
        symbol: value
      }))
    } else if (type === 'input') {
      if (value === null) {
        setDiscountVisibility(false)
        notification.warning({
          key: 'warning',
          placement: 'bottom',
          duration: 0,
          message: 'El valor no puede ser inferior a 0'
        })
      } else {
        rateValue = value
        if (discountSelected === null || discountSelected === undefined) {
          setDiscountSelected(discountOptions.length > 0 ? (discountOptions.some(o => o.default === true) ? discountOptions.find(o => o.default === true).value : null) : null)
        }
      }
      calculateRateTotalPrice(resumeRate.price, units, rateValue, modifyRate.operator, modifyRate.symbol)
      setModifyRate(current => ({
        operator: current.operator,
        value: rateValue,
        symbol: current.symbol
      }))
    }
  }

  const resertModPrice = () => {
    setDiscountVisibility(false)
    setModifyRate(current => ({
      operator: 'minus',
      value: 0,
      symbol: 'sum'
    }))
    calculateRateTotalPrice(resumeRate.price, units, 0, 'minus', 'sum')
  }

  const round = (value, precision) => {
    var multiplier = Math.pow(10, precision || 0)
    return Math.round(value * multiplier) / multiplier
  }

  const calculateRateTotalPrice = (price, unitsVal, modValue, modOperator, modSymbol, pvpPrice) => {
    if (pvpPrice === undefined) {pvpPrice = 0}
    if (modValue === 0) {
      let valRatePrice = price * unitsVal
      let valRatePricePvP = pvpPrice * unitsVal 
      setRatePrice(valRatePrice)
      setRatePricePvP(valRatePricePvP)
    } else {
      if (modSymbol === 'sum') {
        let valRatePrice = modValue * unitsVal
        let valRatePricePvP = pvpPrice * unitsVal
        setRatePrice(valRatePrice)
        setRatePricePvP(valRatePricePvP)
      } else {
        let valRatePrice = round((modValue / 100) * price, 2)
        let valRatePricePvP = round((modValue / 100) * pvpPrice, 2)
        /* valRatePrice = (price + valRatePrice) * unitsVal
        setRatePrice(valRatePrice) */
        if (modOperator === 'add') {
          valRatePrice = round((price + valRatePrice) * unitsVal, 2)
          valRatePricePvP = round((pvpPrice + valRatePricePvP) * unitsVal, 2)
          setRatePrice(valRatePrice)
          setRatePricePvP(valRatePricePvP)
        } else {
          valRatePrice = round((price - valRatePrice) * unitsVal, 2)
          valRatePricePvP = round((pvpPrice - valRatePricePvP) * unitsVal, 2)
          setRatePrice(valRatePrice)
          setRatePricePvP(valRatePricePvP)
        }
      }
    }
  }

  const resetModalRates = () => {
    setRateModal(false)
    setEditRateBloqued(false)
    setUnits(1)
    setResumeRate({})
    setModifyRate({operator: 'minus', value: 0, symbol: 'sum'})
    setRatePrice(0)
    setEditRateIndex(-1)
    setEditRateSelect([])
    setDiscountVisibility(false)
    setDiscountSelected(discountOptions.length > 0 ? (discountOptions.some(o => o.default === true) ? discountOptions.find(o => o.default === true).value : null) : null)
  }

  const saveModalRate = (index) => {
    let paxValue = 0
    let ratioResources = 0
    if (editRateIndex < 0) {
      paxValue = parseInt(resumeRate.pax)
      paxValue = paxValue * units
      ratioResources = resumeRate.ratio_resources * units
      setFinishRates(current => [...current, {
        'units': units,
        'resumeRate': resumeRate,
        'modifyRate': modifyRate,
        'ratePrice': ratePrice,
        'ratePricePvP': ratePricePvP,
        'paxValue': paxValue,
        'ratioResources': ratioResources,
        'discountType': modifyRate.value > 0 ? discountSelected : null,
        'basePrice': resumeRate.price,
        'pvp_price': resumeRate.pvp_price
      }])
      updateAviablePersons(ratioResources, 'minus')
      //setSumUnits(finishRates.reduce((accumulator, object) => {return accumulator + object.units;}, 0);)
    } else {
      paxValue = parseInt(resumeRate.pax)
      paxValue = paxValue * units
      ratioResources = resumeRate.ratio_resources * units
      let items = finishRates
      let item = items[editRateIndex]
      item = {
        'units': units,
        'resumeRate': resumeRate,
        'modifyRate': modifyRate,
        'ratePrice': ratePrice,
        'ratePricePvP': ratePricePvP,
        'paxValue': paxValue,
        'ratioResources': ratioResources,
        'discountType': modifyRate.value > 0 ? discountSelected : null,
        'basePrice': resumeRate.price,
        'pvp_price': resumeRate.pvp_price
      }
      items[editRateIndex] = item
      setFinishRates(items)
      updateAviablePersons(items, 'plus')
    }
    /* console.log({
      'units': units,
      'resumeRate': resumeRate,
      'modifyRate': modifyRate,
      'ratePrice': ratePrice,
      'ratePricePvP': ratePricePvP,
      'paxValue': paxValue,
      'ratioResources': ratioResources,
      'discountType': modifyRate.value > 0 ? discountSelected : null,
      'basePrice': resumeRate.price,
      'pvp_price': resumeRate.pvp_price
    }) */
    resetModalRates()
  }

  const updateAviablePersons = (value, action) => {
    let calc = null
    if (action === "plus") {
      let ratio = value.reduce((accumulator, object) => { return accumulator + object.ratioResources }, 0)
      calc = ratio
    } else {
      let ratio = finishRates.reduce((accumulator, object) => { return accumulator + object.ratioResources }, 0)
      calc = ratio + value
    }
    setAvPersons(calc)
  }

  const deleteFinishedRate = (index) => {
    var newListado = [...finishRates];
    newListado.splice(index, 1);
    setFinishRates(newListado);
    console.log(newListado)
  }

  const editFinishedRate = (index, bloqued) => {
    //console.log(index)
    setEditRateIndex(index)
    setEditRateBloqued(bloqued)
    setRateModal(true)
    setUnits(finishRates[index].units)
    setResumeRate(finishRates[index].resumeRate)
    setModifyRate(finishRates[index].modifyRate)
    setRatePrice(finishRates[index].ratePrice)
    setEditRateSelect([finishRates[index].resumeRate])
    setDiscountSelected(finishRates[index].discountType)
    if (finishRates[index].discountType !== null && finishRates[index].discountType !== undefined) {
      setDiscountVisibility(true)
    } else {
      setDiscountVisibility(false)
    }
  }

  const returnPax = () => {
    form.setFieldValue('turns', null)
    setSelectedTurn(null)
    form.setFieldValue('resources', [])
    setSelectedResources([])
    setOptionsResources([])
    setSelectedResourcesNoUnit([])
    setTurnErrorMsg("")
  }

  const changeSelectedTurn = (value) => {
    setSelectedTurn(value)
    setSelectedResources([])
    setOptionsResources([])
    setSelectedResourcesNoUnit([])
    form.setFieldValue('resources', [])
    //setAvPersons(persons)
    let actividad = form.getFieldValue('activity')
    let pax = finishRates.reduce((accumulator, object) => { return accumulator + object.paxValue }, 0)
    let ratio = finishRates.reduce((accumulator, object) => { return accumulator + object.ratioResources }, 0)
    setAvPersons(ratio)
    //console.log(ratio)
    apiManager.getResourcesBooking(value, actividad, JSON.parse(localStorage.getItem("activeCenter")).id, props.dateStep, pax, byUnitMode ? ratio : 1, props.editMode)
    .then((res) => {
      let resources = res.data.data.resources
      setMaxUnits(res.data.data.maxUnits)
      setMaxUnitsDefault(res.data.data.maxUnits)
      let limitUnits = res.data.data.maxUnits === 0 ? 0 : res.data.data.maxUnits + 1
      //console.log(res.data.data)
      if (res.data.message_error === "") {
        let defaultResources = []
        let avPersonsDefault = ratio
        resources.map((resource, index) => {
          //console.log(resource)
          setOptionsResources(current => [...current, {
            'value': resource.id,
            'label': resource.title,
            'num_available': resource.num_available,
            'by_unit': resource.by_unit,
            'text': resource.text,
            'disabled': false,
            'has_occupation': resource.has_occupation
          }])
          if (index + 1 <= ratio) {
            if (byUnitMode) {
              defaultResources.push(resource.id)
              changeSelectedResources(defaultResources,undefined,undefined,res.data.data.maxUnits)
            }
          }
          if (!byUnitMode) {
            if (avPersonsDefault > 0) {
              if (!resource.has_occupation) {
                limitUnits = limitUnits - 1
              }
              changeSelectedResources([{
                'value': resource.id,
                'label': resource.title,
                'num_available': resource.num_available,
                'disabled': false,
                'has_occupation': resource.has_occupation
              }], avPersonsDefault, index, limitUnits)
              let inputValue = avPersonsDefault <= resource.num_available ? avPersonsDefault : resource.num_available
              avPersonsDefault = avPersonsDefault - inputValue
            }
          }
        })
        setIsLoading(false)
        setTurnErrorMsg("")
      } else {
        setTurnErrorMsg(res.data.message_error)
        //alert(res.data.message_error)
      }
    })
  }

  const resetResources = () => {
    let newOptions = []
    let resetMaxUnits = 0
    for (let option of optionsResources) {
      option.disabled = false
      newOptions.push(option)
      for (let selectedResource of selectedResources) {
        selectedResource = typeof selectedResource === 'object' ? selectedResource.value : selectedResource
        if (option.value === selectedResource) {
          console.log(option)
          if (!option.has_occupation) {
            resetMaxUnits++
          }
        }
      }
    }
    setMaxUnits(maxUnits + resetMaxUnits)
    setOptionsResources(newOptions)
    setSelectedResources([])
    setSelectedResourcesNoUnit([])
    form.setFieldValue('resources', [])
    let ratio = finishRates.reduce((accumulator, object) => { return accumulator + object.ratioResources }, 0)
    setAvPersons(ratio)
  }

  const filteredOptions = optionsResources.filter((o) => !selectedResources.includes(o.label));

  const changeSelectedResources = (value, defaultType, indexItem, limitUnits) => {
    /* const sumUnits = finishRates.reduce((accumulator, object) => {return accumulator + object.units;}, 0);
    console.log(sumUnits) */
    //console.log(value)
    let newItemValue = value[Object.keys(value)[Object.keys(value).length - 1]]
    if (byUnitMode) {
      var maxUnitsValue = limitUnits !== undefined ? limitUnits : maxUnits
      if (maxUnitsValue === 0 || maxUnitsValue < 0 || value.length > maxUnitsValue) {
        form.setFieldValue('resources', [])
        notification.warning({
          key: 'warning',
          placement: 'bottom',
          duration: isB2B ? 0 : 8,
          message: isB2B ? 'No se dispone de recursos suficientes, contacta con el proveedor'
              : 'Alcanzado limite de recursos sin ocupación permitidos'
        })
      } else {
      if (value.length <= finishRates.reduce((accumulator, object) => {return accumulator + object.ratioResources;}, 0)) {
        setSelectedResources(value)
        form.setFieldValue('resources', value)
      } else {
        var index = value.length - 1
        var newListado = [...selectedResources];
        newListado.splice(index, 1);
        form.setFieldValue('resources', newListado)
        setSelectedResources(newListado);
      }
      }
    } else {
      var maxUnitsValue = limitUnits !== undefined ? limitUnits : maxUnits
      if (!newItemValue.has_occupation && maxUnitsValue === 0) {
        form.setFieldValue('resources', selectedResources)
        notification.warning({
          key: 'warning',
          placement: 'bottom',
          duration: isB2B ? 0 : 8,
          message: isB2B ? 'No se dispone de recursos suficientes, contacta con el proveedor'
            : 'Alcanzado limite de recursos sin ocupación permitidos'
        })
      } else {
        let selectedValue = value.at(-1).value
        setSelectedResources(current => [...current, selectedValue])
        form.setFieldValue('resources', value)
        if (defaultType === undefined) {
          var foundIndex = filteredOptions.findIndex(x => x.value === selectedValue)
          filteredOptions[foundIndex].disabled = true
        }
        //console.log(value)
        value.map((item) => {
          let personsAv = defaultType !== undefined ? defaultType : avPersons
          let valueToInput = personsAv <= item.num_available ? personsAv : item.num_available
          let selectedResourceLength = defaultType !== undefined ? 0 : selectedResourcesNoUnit.length
          if (selectedResourceLength > 0) {
            if (selectedResourcesNoUnit.some(e => e.value === item.value)) {} else {
              //console.log(item)
              if (!item.has_occupation) {
                setMaxUnits(current => current - 1)
              }
              setSelectedResourcesNoUnit(current => [...current, {
                value: item.value,
                label: item.label,
                num_available: item.num_available,
                disabled: item.disabled,
                inputValue: valueToInput
              }])
              setAvPersons(current => current - valueToInput)
            }
          } else {
            //if ((indexItem + 1) <= limitUnits || indexItem === undefined) {
            //console.log(item)
            if (!item.has_occupation) {
              setMaxUnits(current => current - 1)
            }
            setSelectedResourcesNoUnit(current => [...current, {
              value: item.value,
              label: item.label,
              num_available: item.num_available,
              disabled: item.disabled,
              inputValue: valueToInput
            }])
            setAvPersons(current => current - valueToInput)
            //}
          }
          setSelectResourceNotUnit(selectResourceNotUnit + 1)
        })
        //setSelectResourceNotUnit(selectResourceNotUnit + 1)
      }
    }
  }

  const saveFinishActivity = () => {
    if (!byUnitMode) {
      form.setFieldValue('resources', selectedResourcesNoUnit)
    }
    props.saveActivity({
      optionsFamilies: optionsFamilies,
      optionsActivities: optionsActivities,
      optionsRates: optionsRates,
      familiesArray: familiesArray,
      finishRates: finishRates,
      optionsTruns: optionsTruns,
      selectedTurn: selectedTurn,
      optionsResources: optionsResources,
      selectedResources: byUnitMode ? selectedResources : selectedResourcesNoUnit,
      rateModal: rateModal,
      persons: persons,
      maxPersons: maxPersons,
      units: units,
      resumeRate: resumeRate,
      modifyRate: modifyRate,
      ratePrice: ratePrice,
      editRateIndex: editRateIndex,
      editRateSelect: editRateSelect,
      activitiesArray: activitiesArray,
      formValues: form.getFieldsValue(),
      indexActivity: props.indexActivity,
      byUnitMode: byUnitMode
    })
  }

  const returnToRates = () => {
    confirmAlert({
      title: 'Vous souhaitez ajouter ou modifier des tarifs ?',
      message: 'Les champs horaire et matériel seront réinitialisés',
      buttons: [
        {
          label: 'Confirmer',
          onClick: () => returnPax(),
          className: 'confirm-btn',
        },
        {
          label: 'Annuler',
          className: 'cancel-btn',
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-confirm-suprimir"
    })
  }

  const returnTruePax = () => {
    setFinishRates([])
    form.setFieldValue('turns', null)
    setSelectedTurn(null)
    form.setFieldValue('resources', [])
    setSelectedResources([])
    setSelectedResourcesNoUnit([])
    setOptionsResources([])
    setTurnErrorMsg("")
    setAvPersons(0)
  }

  const returnToPax = () => {
    confirmAlert({
      title: 'Vous souhaitez modifier l´activité ou le nombre de personnes ?',
      message: 'Les tarifs ajoutés jusqu´à présent seront éliminés et les champs horaire et matériel seront réinitialisés.',
      buttons: [
        {
          label: 'Confirmer',
          onClick: () => returnTruePax(),
          className: 'confirm-btn',
        },
        {
          label: 'Annuler',
          className: 'cancel-btn',
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-confirm-suprimir"
    })
  }

  const confirmCancelChangesActivity = () => {
    confirmAlert({
      title: 'Voulez-vous annuler les modifications apportées ?',
      message: 'L´activité reviendra à son état précédent',
      buttons: [
        {
          label: 'Confirmer',
          onClick: () => props.cancelChangesActivity(),
          className: 'confirm-btn',
        },
        {
          label: 'Annuler',
          className: 'cancel-btn',
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-confirm-suprimir"
    })
  }

  const confirmRemoveRate = (index) => {
    confirmAlert({
      title: 'Voulez-vous supprimer ces tarif?',
      buttons: [
        {
          label: 'Confirmer',
          onClick: () => deleteFinishedRate(index),
          className: 'confirm-btn',
        },
        {
          label: 'Annuler',
          className: 'cancel-btn',
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-confirm-suprimir"
    })
  }

  const removeSelectedResourceNoUnit = (value) => {
    //console.log(value)
    let selectedValue = value
    let foundIndex = filteredOptions.findIndex(x => x.value === selectedValue)
    filteredOptions[foundIndex].disabled = false
    let itemRemoved = filteredOptions.find(x => x.value === selectedValue)
    if (!itemRemoved.has_occupation) {
      setMaxUnits(current => current + 1)
    }
    let newListado = [...selectedResources]
    let selectedIndex = newListado.findIndex(x => x === selectedValue)
    newListado.splice(selectedIndex, 1)
    let newListado2 = [...selectedResourcesNoUnit]
    let selectedIndex2 = newListado2.findIndex(x => x.value === selectedValue)
    let selectedInputValue = newListado2[selectedIndex2].inputValue
    newListado2.splice(selectedIndex2, 1)
    form.setFieldValue('resources', byUnitMode ? newListado : newListado2)
    if (byUnitMode) {
      setSelectedResources(newListado)
    } else {
      setSelectedResources(newListado2)
      setSelectedResourcesNoUnit(newListado2)
    }
    /* setSelectedResources(newListado)
    setSelectedResourcesNoUnit(newListado2) */
    setSelectResourceNotUnit(selectResourceNotUnit + 1)
    setListResourcesKey(listResourcesKey + 1)
    setAvPersons(current => current + selectedInputValue)
  }

  const incressValue = (id) => {
    let alterArray = [...selectedResourcesNoUnit]
    let foundIndex = alterArray.findIndex(x => x.value === id)
    if (alterArray[foundIndex].inputValue !== undefined) {
      alterArray[foundIndex].inputValue = alterArray[foundIndex].inputValue + 1
    } else {
      alterArray[foundIndex].inputValue = 2
    }
    setAvPersons(current => current - 1)
    setListResourcesKey(listResourcesKey + 1)
  }

  const decressValue = (id) => {
    let alterArray = [...selectedResourcesNoUnit]
    let foundIndex = alterArray.findIndex(x => x.value === id)
    if (alterArray[foundIndex].inputValue !== undefined) {
      alterArray[foundIndex].inputValue = alterArray[foundIndex].inputValue - 1
    }
    setAvPersons(current => current + 1)
    setListResourcesKey(listResourcesKey + 1)
  }

  const reloadTurns = (initial) => {
    setReloadTurnDisable(true)
    setSelectedTurn(null)
    form.setFieldValue('turns', null)
    setSelectedResources([])
    setOptionsResources([])
    setSelectedResourcesNoUnit([])
    setMaxUnits(0)
    setMaxUnitsDefault(0)
    form.setFieldValue('resources', [])
    setTurnErrorMsg("")
    //setAvPersons(persons)
    let actividad = form.getFieldValue('activity')
    let pax = initial !== true ? finishRates.reduce((accumulator, object) => { return accumulator + object.paxValue }, 0) : 1
    let ratio = initial !== true ? finishRates.reduce((accumulator, object) => { return accumulator + object.ratioResources }, 0) : 1
    setAvPersons(ratio)
    apiManager.reloadTurns(JSON.parse(
      localStorage.getItem("activeCenter")).id,
      actividad,
      props.dateStep,
      pax,
      byUnitMode ? ratio : 1,
      isB2B ? isB2B : undefined,
      props.editMode ? props.data.bookingId : undefined
    )
    .then((res) => {
      let result = res.data.data
      if (result.length > 0) {
        setOptionsTurns([])
        result.map((turn) => {
          setOptionsTurns(current => [...current, {
            'value': turn.id,
            'label': turn.schedule
          }])
        })
      } else {
        notification.warning({
          key: 'warning',
          placement: 'bottom',
          duration: 0,
          message: 'Il n´y a pas de horaires disponible pour ce nombre de personnes avec les tarifs appliqués'
        })
      }
      setReloadTurnDisable(false)
    })
  }

  return (
    <>
      {/* <Modal
        width={600}
        open={notByUnitResorce}
        centered={true}
        maskClosable={false}
        destroyOnClose={true}
        onCancel={() => SetNotByUnitResorce(false)}
        onOk={() => okModalResourcesNotUnit()}
        closable={false}
        okText="Confirmer"
        cancelText="Annuler"
      >
        <Row>
          <Col span={11}>
            <Form.Item
              label={`Recurso:`}
              name="resources"
            >
              <Select
                mode="tags"
                key={selectResourceNotUnit}
                placeholder="Selecciona un recurso"
                value={selectedResources}
                maxTagCount={0}
                onChange={(e, a) => changeSelectedResources(a)}
                disabled={selectedTurn === null || turnErrorMsg !== ""}
                style={{
                  width: '100%',
                }}
                options={filteredOptions.map((item) => ({
                  value: item.value,
                  label: `${item.text} (Disponibles: ${item.num_available})`,
                  disabled: item.disabled,
                }))}
              />
            </Form.Item>
            <p style={{marginTop: 20, fontSize: 14}}>Pers. à placer: <b style={{fontSize: 16}}>{ persons }</b></p>
          </Col>
          <Col span={12} offset={1}>
            <Row justify="space-between">
              {
                selectedResources.map((resource) => (
                  <Col span={11}>
                    <div style={{backgroundColor: "#ececec", padding: "6px 10px", textAlign: "center", borderRadius: 5, marginBottom: 15, position: "relative"}}>
                      <Cancel style={{position: "absolute", top: -8, right: -8}} onClick={() => {removeSelectedResourceNoUnit(resource)}} />
                      <p>{ filteredOptions.find(x => x.value === resource).text }</p>
                    </div>
                  </Col>
                ))
              }
            </Row>
          </Col>
        </Row>
      </Modal> */}
      <Modal
        maskClosable={false}
        open={rateModal}
        centered={true}
        onCancel={() => resetModalRates()}
        onOk={() => saveModalRate()}
        closable={false}
        destroyOnClose={true}
        width={600}
        className="modalTarifas"
        okText="Confirmer"
        cancelText="Annuler"
        okButtonProps={{
          className: "okBtn",
          disabled: resumeRate.value === undefined || (discountVisibility && discountSelected === null),
        }}
      >
        <Row
          align={'bottom'}
          justify={'space-between'}
        >
          <Col span={15}>
            <label style={{marginBottom: '4px'}}>Tarifa:</label>
            <Select
              showSearch
              options={editRateIndex < 0 ? optionsRates : editRateSelect}
              style={{width: '100%'}}
              //onChange={(e, a) => selectedRate(e, a)}
              onChange={(e) => selectRate(e)}
              defaultValue={editRateIndex < 0 ? null : resumeRate.value}
              disabled={editRateIndex >= 0 || editRateBloqued}
              placeholder="Sélectionner un prix"
              filterOption={(input, option) =>
                (option.label).toLowerCase().includes(input.toLowerCase())
              }
            />
          </Col>
          <Col span={8} style={{display: 'flex', flexWrap: 'wrap'}}>
            <label style={{width: '100%', marginBottom: '4px'}}>Unité:</label>
            <InputNumber
              value={units}
              style={{width: '100px'}}
              onChange={(e) => unitsValueChange(e)}
              disabled={editRateBloqued}
            />
            <Button
              className="iconCircle icon-24"
              style={{marginInline: '5px', backgroundColor: "transparent"}}
              onClick={() => incressUnit()}
              disabled={editRateBloqued}
            >
              <PlusCircleFilled className="greyColor" />
            </Button>
            <Button
              className="iconCircle icon-24"
              style={{backgroundColor: "transparent"}}
              onClick={() => decressUnit()}
              disabled={editRateBloqued}
            >
              <MinusCircleFilled className="greyColor" />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {
              Object.keys(resumeRate).length > 0 ? 
              (
                <Row justify="space-between" align="middle">
                  <Col span={15}>
                    <div className="resumeRateBox">
                      <p><b>Tarif</b>: {resumeRate.name}</p>
                      <p><b>Prix du tarif</b>: {modifyRate.value === 0 ? `${!isB2B ? resumeRate.price : resumeRate.pvp_price}€` : (
                        <><del style={{color: 'red', fontSize: '14px'}}>{resumeRate.price}€</del> {
                          modifyRate.symbol === 'sum' ? `${modifyRate.value}€` :
                            modifyRate.operator === 'add' ?
                            `${resumeRate.price + ((modifyRate.value / 100) * resumeRate.price)}€`
                            : `${resumeRate.price - ((modifyRate.value / 100) * resumeRate.price)}€`
                        }</>
                      )}</p>
                      <p><b>Unité</b>: {units}</p>
                      <p><b>Prix total</b>: {!isB2B ? ratePrice : ratePricePvP}€</p>
                    </div>
                  </Col>
                  <Col span={8}>
                    {
                      !isB2B ?
                        <div>
                          <label style={{width: '100%', marginBottom: 6}}>Modifier prix</label>
                          <InputNumber
                            addonBefore={modifyRate.symbol === 'percent' ? selectBefore : ""}
                            addonAfter={selectAfter}
                            value={modifyRate.value}
                            min={0}
                            onChange={(e) => modRatePrice(e, 'input')}
                            style={{width: '80%'}}
                          />
                          <Button
                            className="iconCircle icon-24"
                            style={{marginLeft: 5, backgroundColor: "transparent"}}
                            disabled={modifyRate.value === 0}
                            title="Resetear precio"
                            onClick={() => resertModPrice()}
                          >
                            <UndoOutlined className="greyColor" />
                          </Button>
                        </div>
                      : <></>
                    }
                    {
                      discountVisibility === true ?
                      <div>
                        <Select
                          showSearch
                          style={{width: "100%", marginTop: 15}}
                          options={discountOptions}
                          value={discountSelected}
                          onChange={(e) => setDiscountSelected(e)}
                          placeholder="Tipo de descuento"
                          filterOption={(input, option) =>
                            (option.label).toLowerCase().includes(input.toLowerCase())
                          }
                        />
                      </div>
                      : ""
                    }
                  </Col>
                </Row>
              ) : ""
            }
          </Col>
        </Row>
        {/* {
          [...Array(persons),].map((value, index) => (
            <Button
              key={index}
              className="unitBtn"
              disabled={true}
            ><UserOutlined /></Button>
          ))
        } */}
      </Modal>
      {/* <CircleSpinnerOverlay
        style={{zIndex: 1500}}
        color="#ff4d4f"
        outerBorderWidth="6"
        outerBorderOpacity="0.3"
        loading={isLoading}
      /> */}
      <Row>
        <Col span={24}>
          <Form
            layout="vertical"
            form={form}
            //onValuesChange={(e, a) => formUpdate(e, a)}
          >
            <Row
              className="activityForm"
            >
              <Col span={24}>
                <Divider style={{marginTop: '0'}} plain>Activité et tarifs</Divider>
              </Col>
              <Col
                span={12}
                className="activityFormFirst"
              >
                <Form.Item
                  label="Famille d'activités:"
                  name="activityFamily"
                  rules={
                    [{
                      required: true,
                      message: "Champ obligatoire",
                    }]
                  }
                >
                  <Select
                    showSearch
                    options={optionsFamilies}
                    onChange={(e) => loadActivities(e)}
                    disabled={finishRates.length > 0 || isLoading}
                    loading={isLoading}
                    placeholder={isLoading ? "Chargement des données..." : "Sélectionner une famille d´activités"}
                    filterOption={(input, option) =>
                      (option.label).toLowerCase().includes(input.toLowerCase())
                    }
                    //loading={optionsFamilies.length > 0 ? false : true}
                    //disabled={optionsFamilies.length > 0 ? false : true}
                  />
                </Form.Item>
                <Form.Item
                  label="Activité:"
                  name="activity"
                  rules={
                    [{
                      required: true,
                      message: "Champ obligatoire",
                    }]
                  }
                >
                  <Select
                    showSearch
                    options={optionsActivities}
                    disabled={optionsActivities.length === 0 || finishRates.length > 0 ? true : false}
                    onChange={(e) => loadRates(e)}
                    placeholder="Sélectionnez une famille"
                    filterOption={(input, option) =>
                      (option.label).toLowerCase().includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
                <Row
                  justify="space-between"
                  align="bottom"
                >
                  <Col span={9} style={{display: 'flex'}}>
                    <InputNumber
                      addonBefore={<UserOutlined />}
                      //disabled={true}
                      disabled={optionsRates.length === 0 || finishRates.length > 0 ? true : false}
                      value={persons}
                      onChange={(e) => personsValueChange(e)}
                      style={{width: '100px'}}
                    />
                    <Button
                      className="iconCircle icon-24"
                      style={{marginInline: '5px', backgroundColor: "transparent"}}
                      disabled={optionsRates.length === 0 || finishRates.length > 0 ? true : false}
                      onClick={() => incressPerson()}
                    >
                      <PlusCircleFilled className="greyColor" />
                    </Button>
                    <Button
                      className="iconCircle icon-24"
                      disabled={optionsRates.length === 0 || finishRates.length > 0 ? true : false}
                      style={{backgroundColor: "transparent"}}
                      onClick={() => decressPerson()}
                    >
                      <MinusCircleFilled className="greyColor" />
                    </Button>
                  </Col>
                  <Col span={14} style={{display: 'flex', justifyContent: 'right'}}>
                    {/* {
                      finishRates.length > 0 ?
                      <Button
                        className={`main-btn${ selectedTurn === null ? "" : " disabledClick" }`}
                        style={{ marginRight: 20 }}
                        onClick={() => returnToPax()}
                      >Volver a pax</Button>
                      : ""
                    } */}
                    {/* {
                      selectedTurn === null ?
                      <Button
                        className="main-btn btn-medium"
                        onClick={() => { setRateModal(true)} }
                        disabled={optionsRates.length > 0 ? false : true}
                      >Ajouter tarifs</Button>
                      :
                      <Button
                        className="main-btn btn-medium"
                        onClick={() => returnPax()}
                      >Volver a pax</Button>
                    } */}
                    {/* <Form.Item
                      label="Tarifas:"
                      name="rates"
                      rules={
                        [{
                          required: true,
                          message: "Champ obligatoire",
                        }]
                      }
                    >
                      <Select
                        options={optionsRates}
                        disabled={optionsRates.length > 0 ? false : true}
                        //onChange={(e, a) => selectedRate(e, a)}
                      />
                    </Form.Item> */}
                  </Col>
                </Row>
                {
                  finishRates.length > 0 ?
                  <Button
                    className={`main-btn`}
                    style={{ position: "absolute", bottom: 0, right: 20, left: "unset" }}
                    onClick={() => returnToPax()}
                  >Volver a pax</Button>
                  : ""
                }
              </Col>
              <Col
                span={12}
                className="activityFormSecond"
                style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}
              >
                <Row justify="space-between">
                {
                  finishRates.map((rate, index) =>
                    <Col span={11} className="resumeFinishRateBox" key={`rateItem-${index}`}>
                      <div>
                        <p><b>Tarif</b>: {rate.resumeRate.name}</p>
                        <p><b>Prix du tarif</b>: {
                          isB2B ? rate.pvp_price + "€"
                          :
                            rate.modifyRate.value === 0 ? `${rate.resumeRate.price}€` : (
                              <><del style={{color: 'red', fontSize: '14px'}}>{rate.resumeRate.price}€</del> {
                                rate.modifyRate.symbol === 'sum' ? `${rate.modifyRate.value}€` :
                                  rate.modifyRate.operator === 'add' ?
                                    `${rate.resumeRate.price + ((rate.modifyRate.value / 100) * rate.resumeRate.price)}€`
                                    : `${rate.resumeRate.price - ((rate.modifyRate.value / 100) * rate.resumeRate.price)}€`
                              }</>
                        )}</p>
                        <p><b>Unité</b>: {rate.units}</p>
                        <p><b>Prix total</b>: {!isB2B ? rate.ratePrice : rate.ratePricePvP}€</p>
                        <div className="actionBtns">
                          <Button
                            className={`iconCircle editBtn${ isB2B && selectedTurn !== null ? " disabledClick" : "" }`}
                            title="Editar tarifa"
                            onClick={() => {
                              isB2B && selectedTurn !== null ?
                                returnToRates()
                              : editFinishedRate(index, selectedTurn !== null ? true : false)
                            }}
                            style={{width: 27}}
                            //disabled={selectedTurn !== null}
                          ><FormOutlined className="white" /></Button>
                          <Button
                            className={ `iconCircle deleteBtn${ selectedTurn !== null ? " disabledClick" : "" }` }
                            title="Eliminar tarifa"
                            onClick={() => { selectedTurn !== null ? returnToRates() : confirmRemoveRate(index) }}
                            style={{width: 27}}
                            //disabled={selectedTurn !== null}
                          ><DeleteOutlined className="white" /></Button>
                        </div>
                      </div>
                    </Col>
                  )
                }
                </Row>
                <Row>
                  <Col span={24}>
                    <Button
                      className={`main-btn`}
                      //className="main-btn btn-medium"
                      onClick={() => { selectedTurn ? returnToRates() : setRateModal(true)} }
                      disabled={optionsRates.length > 0 ? false : true}
                    >Ajouter tarifs</Button>
                  </Col>
                </Row>
              </Col>
              <Col
                span={24}
                hidden={finishRates.reduce((accumulator, object) => { return accumulator + object.paxValue }, 0) < persons}
              >
                <Divider plain style={{marginTop: '25px'}}>Horaires et matériel</Divider>
                <Row>
                  <Col
                    span={12}
                    className="activityFormFirst"
                    style={{display: 'flex'}}
                  >
                    <Form.Item
                      label="Turno:"
                      name="turns"
                      rules={
                        [{
                          required: true,
                          message: "Champ obligatoire",
                        }]
                      }
                      style={{width: '90%'}}
                    >
                      <Select
                        showSearch
                        options={optionsTruns}
                        onChange={(e) => changeSelectedTurn(e)}
                        placeholder="Sélectionnez un horaire"
                        disabled={reloadTurnDisable}
                        loading={reloadTurnDisable}
                        style={{width: '100%'}}
                        //disabled={optionsActivities.length === 0 || finishRates.length > 0 ? true : false}
                        //onChange={(e) => loadRates(e)}
                        filterOption={(input, option) =>
                          (option.label).toLowerCase().includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>
                    <Tooltip placement="top" title="Recalcular turnos" arrow={false}>
                      <Button
                        style={{marginTop: 31}}
                        disabled={reloadTurnDisable}
                        onClick={() => reloadTurns()}
                      ><ReloadOutlined /></Button>
                    </Tooltip>
                  </Col>
                  <Col
                    span={12}
                    className="activityFormSecond"
                    style={isB2B ? {display: 'inline-flex', alignItems: 'center'} : {}}
                  >
                    {
                      byUnitMode ?
                        <>
                          <Button
                            className={ `iconCircle deleteBtn` }
                            title="Vaciar recursos"
                            onClick={() => { resetResources() }}
                            style={{width: 30, position: 'absolute', top: '-10px', left: 'calc(100% - 30px)', zIndex: 1}}
                            disabled={selectedResources.length <= 0}
                          ><DeleteOutlined className="white" /></Button>
                          <Form.Item
                            label={`Recursos (${finishRates.reduce((accumulator, object) => {return accumulator + object.ratioResources;}, 0)}):`}
                            name="resources"
                            style={isB2B ? {display: 'none'} : {}}
                            rules={
                              [
                                {
                                  required: true,
                                  message: "Champ obligatoire",
                                },
                                {
                                  validator: (rule, value, callback) => {
                                    if (value) {
                                      if (value.length > finishRates.reduce((accumulator, object) => {return accumulator + object.ratioResources;}, 0)) {
                                        callback(`No se pueden añadir más de ${finishRates.reduce((accumulator, object) => {return accumulator + object.ratioResources;}, 0)} recursos`);
                                      } else if (value.length <= finishRates.reduce((accumulator, object) => {return accumulator + object.ratioResources;}, 0)) {
                                        callback();
                                      }
                                    }
                                    return;
                                  }
                                }
                              ]
                            }
                          >
                            <Select
                              mode="tags"
                              placeholder="Ajouter matériel"
                              value={selectedResources}
                              onChange={(e) => changeSelectedResources(e)}
                              disabled={selectedTurn === null || turnErrorMsg !== ""}
                              style={{
                                width: '100%',
                              }}
                              options={filteredOptions.map((item) => ({
                                value: item.value,
                                label: item.label,
                              }))}
                            />
                          </Form.Item>
                        </>
                      :
                        <Row
                          style={isB2B ? {display: 'none'} : {}}
                        >
                          <Col span={24}>
                            <p style={{paddingBottom: 8, fontSize: 14}}>Recursos:</p>
                            {
                              selectedResourcesNoUnit.length > 0 ?
                                <Button
                                  className={ `iconCircle deleteBtn` }
                                  title="Vaciar recursos"
                                  onClick={() => { resetResources() }}
                                  style={{width: 30, position: 'absolute', bottom: 10, left: 'calc(100% - 20px)'}}
                                ><DeleteOutlined className="white" /></Button>
                              : <></>
                            }
                          </Col>
                          <Col span={7}>
                            <Form.Item
                              //label={`Recurso:`}
                              name="resources"
                            >
                              <Select
                                mode="tags"
                                key={selectResourceNotUnit}
                                placeholder="Selecciona un recurso"
                                value={selectedResources}
                                maxTagCount={0}
                                onChange={(e, a) => changeSelectedResources(a)}
                                //onSelect={(e, a) => console.log(a)}
                                disabled={
                                  selectedTurn === null ||
                                  turnErrorMsg !== "" ||
                                  avPersons === 0
                                }
                                className="resources-select-nounit"
                                style={{
                                  width: '100%',
                                }}
                                options={filteredOptions.map((item) => ({
                                  value: item.value,
                                  label: `${item.text} (${item.num_available} disponibles)`,
                                  disabled: item.disabled,
                                  num_available: item.num_available,
                                  has_occupation: item.has_occupation
                                }))}
                              />
                            </Form.Item>
                            {/* {
                              selectedResourcesNoUnit.length >= maxUnits && maxUnits !== 0 ?
                                <span
                                  className="red"
                                  style={{
                                    position: 'absolute',
                                    display: 'block',
                                    lineHeight: '14px',
                                    marginTop: -20,
                                    fontSize: 12,
                                    whiteSpace: 'nowrap'
                                  }}
                                >
                                  {
                                    avPersons !== 0 && selectedResourcesNoUnit.length === maxUnits ?
                                      "Máximo de recursos alcanzado"
                                    : selectedResourcesNoUnit.length > maxUnits ?
                                        "Limite de recursos superado"
                                      : ""
                                  }
                                </span>
                              : ""
                            } */}
                            <p style={{marginTop: 20, fontSize: 14}}>Pers. à placer: <b style={{fontSize: 16}}>{ avPersons }</b></p>
                          </Col>
                          <Col span={16} offset={1}>
                            <Row justify="space-around" key={listResourcesKey}>
                              {
                                selectedResourcesNoUnit.map((resource) => (
                                  <Col span={9}>
                                    <div style={{backgroundColor: "#ececec", padding: "6px 10px", textAlign: "center", borderRadius: 5, marginBottom: 15, position: "relative"}}>
                                      <Tooltip title="Quitar recurso">
                                        <Cancel style={{position: "absolute", top: -8, right: -8}} onClick={() => {removeSelectedResourceNoUnit(resource.value)}} />
                                      </Tooltip>
                                      <p>{ filteredOptions.find(x => x.value === resource.value).text }</p>
                                      <InputNumber
                                        addonBefore={<UserOutlined />}
                                        value={resource.inputValue !== undefined ? resource.inputValue : 1}
                                        min={1}
                                        max={filteredOptions.find(e => e.value === resource.value).num_available}
                                        disabled={true}
                                        className="resource-input-value"
                                        style={{width: '70px', margin: '5px 0 2px'}}
                                      />
                                      <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <Button
                                          className="iconCircle icon-24"
                                          disabled={resource.inputValue === undefined || resource.inputValue === 1 ? true : false}
                                          style={{backgroundColor: "transparent"}}
                                          onClick={() => decressValue(resource.value)}
                                        >
                                          <MinusCircleFilled className="greyColor" />
                                        </Button>
                                        <Button
                                          className="iconCircle icon-24"
                                          style={{marginInline: '5px', backgroundColor: "transparent"}}
                                          disabled={resource.inputValue >= filteredOptions.find(e => e.value === resource.value).num_available || avPersons === 0 ? true : false}
                                          onClick={() => incressValue(resource.value)}
                                        >
                                          <PlusCircleFilled className="greyColor" />
                                        </Button>
                                      </div>
                                    </div>
                                  </Col>
                                ))
                              }
                            </Row>
                          </Col>
                        </Row>
                    }
                    {
                      turnErrorMsg !== "" && finishRates.reduce((accumulator, object) => {return accumulator + object.ratioResources;}, 0) > 0 ?
                        <Tag color="#ff372e" className="simpleTag">No hay recursos suficientes</Tag>
                      : ""
                    }
                    {
                      turnErrorMsg !== "" && finishRates.reduce((accumulator, object) => {return accumulator + object.ratioResources;}, 0) === 0 ?
                        <Tag color="#53b454" className="simpleTag">No hacen falta recursos</Tag>
                      : ""
                    }
                  </Col>
                </Row>
              </Col>
              {
                props.data !== undefined ?
                  <Col span={24} style={{textAlign: "center", marginTop: 30}}>
                    <Button onClick={() => confirmCancelChangesActivity()} className="basic-btn" style={{marginRight: 15}}>Annuler les modifications</Button>
                    {
                      byUnitMode ?
                        selectedResources.length === finishRates.reduce((accumulator, object) => {return accumulator + object.ratioResources;}, 0) && selectedTurn !== null ?
                          <Button onClick={() => saveFinishActivity()} className="main-btn">Sauvegarder</Button>
                        :
                          ""
                      :
                      avPersons === 0 && selectedTurn !== null ?
                          <Button onClick={() => saveFinishActivity()} className="main-btn">Sauvegarder</Button>
                        :
                          ""
                    }
                  </Col>
                :
                  byUnitMode ?
                    selectedResources.length === finishRates.reduce((accumulator, object) => {return accumulator + object.ratioResources;}, 0) && selectedTurn !== null ?
                      <Col span={24} style={{textAlign: "center", marginTop: 30}}>
                        <Button onClick={() => saveFinishActivity()} className="main-btn">Sauvegarder</Button>
                      </Col>
                    :
                      ""
                  :
                    avPersons === 0 && selectedTurn !== null ?
                      <Col span={24} style={{textAlign: "center", marginTop: 30}}>
                        <Button onClick={() => saveFinishActivity()} className="main-btn">Sauvegarder</Button>
                      </Col>
                    :
                      ""
              }
            </Row>
          </Form>
        </Col>
        <Col span={24} style={{textAlign: "center", marginTop: 20}}>
          {
            props.cancelBtnState === true ?
              <Button onClick={() => props.cancelChangesActivity()} className="basic-btn">Cancelar nueva actividad</Button>
            : ""
          }
        </Col>
      </Row>
    </>
  )
}