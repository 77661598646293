import React, { useState, useEffect } from "react"
import { Table, Tooltip, Tag, Button, Row, Col, Form, Input } from "antd"
import apiManager from "../../api/apiManager"
import Loader from "../Loader/Loader"
import { SyncProblem, ContactEmergency, Replay, PersonOutline, Download } from "@mui/icons-material"
import { SearchOutlined } from "@ant-design/icons"
import InvoiceModal from "../Modals/InvoiceModal"
import InvoiceRect from "../Modals/InvoiceRect"

export default function TableInvoices() {

  useEffect(()=> {
    loadTableData()
  }, [])

  const [isLoading, setIsLoading] = useState(false)
  const [dataTable, setDataTable] = useState([])
  const [dataTableBase, setDataTableBase] = useState([])
  const [channelsInvoice, setChannelsInvoice] = useState([])
  const [socialInvoice, setSocialInvoice] = useState([])
  const [deliveriesInvoice, setDeliveriesInvoice] = useState([])
  const [isModalInvoice, setIsModalInvoice] = useState(false)
  const [invoiceData, setInvoiceData] = useState([])
  const [isModalInvoiceRect, setIsModalInvoiceRect] = useState(false)
  const [formSearch] = Form.useForm()

  const loadTableData = () => {
    setIsLoading(true)
    apiManager.listInvoices(JSON.parse(localStorage.getItem("activeCenter")).id)
    .then((res) => {
      let result = res.data.data
      filterSocialInvoice(result)
      filterChannelsInvoice(result)
      filterDeliveriesInvoice(result)
      setDataTable(result)
      setDataTableBase(result)
      //console.log(result)
      setIsLoading(false)
    })
  }

  const filterChannelsInvoice = (elements) => {
    setChannelsInvoice([])
    let list = []
    elements.map(delivery => {
      if (list.some((e) => e.value === delivery.sales_channel)) {
        
      } else {
        list.push({value: delivery.sales_channel})
        setChannelsInvoice(current => [...current, {
          'text': delivery.sales_channel,
          'value': delivery.sales_channel
        }])
      }
    })
  }

  const filterSocialInvoice = (elements) => {
    setSocialInvoice([])
    let list = []
    elements.map(invoice => {
      if (list.some((e) => e.value === invoice.social_reason_fact) || invoice.social_reason_fact === "") {
        
      } else {
        list.push({value: invoice.social_reason_fact})
        setSocialInvoice(current => [...current, {
          'text': invoice.social_reason_fact,
          'value': invoice.social_reason_fact
        }])
      }
    })
  }

  const filterDeliveriesInvoice = (elements) => {
    setDeliveriesInvoice([])
    let list = []
    elements.map(invoice => {
      invoice.delivery_list.map(delivery => {
        if (list.some((e) => e.value === delivery)) {
        
        } else {
          list.push({value: delivery})
          setDeliveriesInvoice(current => [...current, {
            'text': delivery,
            'value': delivery
          }])
        }
      })
    })
  }

  const openInfoInvoice = (value) => {
    apiManager.getInvoice(value)
    .then((res) => {
      let result = res.data.data[0]
      setInvoiceData(result)
      setIsModalInvoice(true)
    })
  }

  const openInfoInvoiceRect = (value) => {
    apiManager.getInvoiceRect(value)
    .then((res) => {
      let result = res.data.data[0]
      result.is_rect = true
      setInvoiceData(result)
      setIsModalInvoice(true)
    })
  }

  const closeInfoInvoice = () => {
    setIsModalInvoice(false)
  }

  const closeInvoiceRect = (reload) => {
    setIsModalInvoiceRect(false)
    if (reload !== undefined) {
      loadTableData()
    }
  }

  const searchOnTable = (text) => {
    if (text.text !== undefined && text.text !== "") {
      let tableData = dataTableBase
      const filterResult = tableData.filter(
        (item) =>
          item.client_info
            .toLowerCase().includes(text.text.toLowerCase()) ||
          item.invoice_id
            .toLowerCase().includes(text.text.toLowerCase()) ||
          item.social_reason_fact.toLowerCase().includes(text.text.toLowerCase()) ||
          item.sales_channel.toLowerCase().includes(text.text.toLowerCase()) ||
          item.invoice_date.toLowerCase().includes(text.text.toLowerCase())
      )
      setDataTable(filterResult)
    } else {
      setDataTable(dataTableBase)
    }
  }

  const columns = [
    {
      title: 'ID Factura',
      dataIndex: 'invoice_id',
      key: 'invoice_id',
      sorter: (a, b) => a.invoice_id.localeCompare(b.invoice_id),
    },
    {
      title: 'Date de facturation',
      dataIndex: 'invoice_date',
      key: 'invoice_date',
      sorter: (a, b) => a.invoice_date_ts - b.invoice_date_ts,
    },
    {
      title: 'Razón social',
      dataIndex: 'social_reason_fact',
      key: 'social_reason_fact',
      filters: socialInvoice,
      onFilter: (value, record) => record.social_reason_fact.includes(value) === true,
    },
    {
      title: 'Cliente',
      dataIndex: 'client_info',
      key: 'client_info',
    },
    {
      title: 'Partenaires',
      dataIndex: 'sales_channel',
      key: 'sales_channel',
      filters: channelsInvoice,
      onFilter: (value, record) => record.sales_channel.indexOf(value) === 0,
    },
    {
      title: 'Liste des bordereaux',
      dataIndex: 'delivery_list',
      key: 'delivery_list',
      width: 200,
      filters: deliveriesInvoice,
      filterSearch: true,
      onFilter: (value, record) => record.delivery_list.includes(value) === true,
      render: (element) => element.length > 4 ?
        element.map((id, i, row) => {
          if (i < 3) {
            return <Tag className="simple">{element[i]}</Tag>
          } else if(i === 4) {
            return <Tooltip color="#fff" placement="right" title={
              element.map((ide, index, rowe) => {
                if (index > 2) {
                  return <Tag className="simple">{element[index]}</Tag>
                }
              })
            }>
              <Tag className="simple">{ "+" + (element.length - 3).toString() }</Tag>
            </Tooltip>
          }
        })
      : element.map((id, i, row) =>
        <Tag className="simple">{element[i]}</Tag>
        )
    },
    {
      title: 'Base imponible',
      dataIndex: 'base_import',
      key: 'base_import',
      align: 'right',
      render: (text) => text.map((elem) => {
        return <><span>{ Number(elem).toFixed(2).replace(/[.,]00$/, "") + "€" }</span><br /></>
      })
    },
    {
      title: 'Tipo VAT',
      dataIndex: 'vat_type',
      key: 'vat_type',
      align: 'right',
      render: (text) => text.map((elem) => {
        return <><span>{ Number(elem).toFixed(2).replace(/[.,]00$/, "") + "%" }</span><br /></>
      })
    },
    {
      title: 'Importe VAT',
      dataIndex: 'vat_import',
      key: 'vat_import',
      align: 'right',
      render: (text) => text.map((elem) => {
        return <><span>{ Number(elem).toFixed(2).replace(/[.,]00$/, "") + "€" }</span><br /></>
      })
    },
    {
      title: 'TOTAL factura',
      dataIndex: 'total_import',
      key: 'total_import',
      align: 'right',
      render: (text) => <span>{ Number(text).toFixed(2).replace(/[.,]00$/, "") + "€" }</span>
    },
    {
      title: 'Acciones',
      dataIndex: 'invoice_download',
      key: 'invoice_download',
      align: 'center',
      render: (text, record) => 
        <div style={{display: 'flex', gap: 5, justifyContent: 'center'}}>
          <Tooltip title="Descargar factura">
            <Button
              style={{padding: 6}}
              className="main-btn radius-circle"
              onClick={() => record.is_rect ? openInfoInvoiceRect(record.invoice_id) : openInfoInvoice(record.invoice_id)}
            ><Download style={{fill: '#FFF'}} /></Button>
          </Tooltip>
        </div>
    },
  ]

  return (
    <>
      <Loader controller={isLoading} />
      <InvoiceModal
        open={isModalInvoice}
        invoice={invoiceData}
        closeInfoInvoiceFunc={closeInfoInvoice}
      />
      <InvoiceRect
        open={isModalInvoiceRect}
        closeInvoiceRectFunc={closeInvoiceRect}
        //generateDelivery={generateDelivery}
      />
      <Row
        justify={'space-between'}
        style={{marginBottom: 20}}
      >
        <Col span={8}>
          <Form
            className="formSerachBooking"
            form={formSearch}
            onFinish={(values) => searchOnTable(values)}
            style={{marginRight: 20}}
          >
            <Form.Item
              name="text"
              style={{marginBottom: 0}}
            >
              <Input
                placeholder="Recherche..."
              />
            </Form.Item>
            <Form.Item
              style={{marginBottom: 0}}
            >
              <Button
                className="iconCircle"
                htmlType="submit"
              ><SearchOutlined className="white" /></Button>
            </Form.Item>
          </Form>
        </Col>
        <Col span={8}></Col>
        <Col span={8} style={{display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 8}}>
          <Button
            type="primary"
            shape="round"
            size='large'
            className="button-rounded-main"
            onClick={() => setIsModalInvoiceRect(true)}
            style={{marginRight: 6}}
          >
            <p>Crear factura rectificativa</p>
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={dataTable} 
        bordered
        //onChange={onChange}
        className="mainTable"
        //onRow={(record, rowIndex) => { return {onClick: event => console.log(record, rowIndex, event)}}}
        rowKey={record => record.delivery_id}
      />
    </>
  )
}