/* eslint-disable */

import React, { useState, useEffect, createContext } from "react";
import axios from "axios";

//UI
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import ModalMed from "../UI/ModalMed";
import Form from "react-bootstrap/Form";
import Carrusel from "../UI/Carousel";

//styles
import styles from "./tablaSimple.module.css";

export const CarrouselDateContext = createContext();

export default function TablaSimple(props) {
  useEffect(() => {
    getReservas();
    getReservaInfoById();
    // carrouselDateFilter();
  }, []);

  const today = JSON.parse(localStorage.getItem("today"));

  const [modalMedShow, setModalMedShow] = useState(false);

  //get reservas
  const [reservas, setReservas] = useState([]);
  const [searchApiData, setSearchApiData] = useState([]);
  const [totalPax, setTotalPax] = useState([]);


  //canal reserva
  const centerId = JSON.parse(localStorage.getItem("activeCenter"));


  const getReservas = async () => {
    await axios
      .get(
        process.env.REACT_APP_FHIOS_API_URL + "simple_booking/between_dates?oneDayStartDate=" +
          today +
          "&centerId=" +
          centerId.id
      )
      .then((res) => {
        setReservas(res.data.data);
        setSearchApiData(res.data.data);
        setTotalInfoCalendar(res.data.data);
      });
  };

  //Ha llegado cliente check
  const [haLlegadoCliente, setHaLlegadoCliente] = useState(false);
  const haLlegadoClienteCheck = async (identifier) => {
 
    const response = await axios
      .post(
        process.env.REACT_APP_FHIOS_API_URL + "booking/update_client_arrived",
        JSON.stringify({ booking: identifier, status: haLlegadoCliente })
      )
      .then((res) => {
        setHaLlegadoCliente(!haLlegadoCliente);
        getReservas();
      });
  };

  //carousel dat efilter

  const carrouselDateFilter = (data) => {
    console.log(data, "reservas???");
    if (data.length === 0) {
      console.log("empty");
      setReservas([]);
    } else {
      setReservas(data);
      setSearchApiData(data);
    }
    setTotalInfoCalendar(data);
    resetRadioButtons();

  };

  //Checkbox filter

  const checkBoxFilter = (e) => {
    if (e.target.value === "") {
      setReservas(searchApiData);
      e.target.checked = false;
    } else {
      const filterResult = searchApiData.filter((item) =>
        item.status.toLowerCase() == e.target.value.toLowerCase()
      );

      setReservas(filterResult);
    }
  };

  //serachBar
  const [searchValue, setSearchValue] = useState("");

  const handleFilter = (e) => {
    if (e.target.value == "") {
      setReservas(searchApiData);
      resetRadioButtons();
    } else {
      console.log(reservas,'reservas');
      console.log(searchApiData,'searchapi');
      const filterResult = reservas.filter(
        (item) =>
          item.client_info
            .toLowerCase().includes(e.target.value.toLowerCase()) ||
          item.identifier
            .toLowerCase().includes(e.target.value.toLowerCase()) ||
          item.status.toLowerCase().includes(e.target.value.toLowerCase()) ||
          item.channel.toLowerCase().includes(e.target.value.toLowerCase()) ||
          item.activity.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setReservas(filterResult);
    }
    setSearchValue(e.target.value);
  };

  //Pagination

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(25);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = reservas.slice(firstPostIndex, lastPostIndex);

  //get reservas Info by ID
  const [reservaInfo, setReservaInfo] = useState();
  const [totalPrice, setTotalPrice] = useState();
  const [pendingPrice, setPendingPrice] = useState();
  const [statusBooking, setStatusBooking] = useState();
  const [historialPagos, setHistorialPagos] = useState();
  // console.log(reservaInfo, "hiii");

  const getReservaInfoById = (id) => {
    axios
      .get(process.env.REACT_APP_FHIOS_API_URL + "booking/obtain?bookingId=" + id)
      .then((res) => {
        setReservaInfo(res.data.data);
        if(res.data.data.length>0){
          setTotalPrice(res.data.data[0].final_price);
          setPendingPrice(res.data.data[0].pending_price);
          setStatusBooking(res.data.data[0].status);
          setHistorialPagos(res.data.data[0].list_payments)
        }
      });
  };

  const showReservaInfo = (id) => {
    getReservaInfoById(id);
    setModalMedShow(true);
  };

  const setTotalInfoCalendar = (data ) => {
    let totalPax=0;
    let totalPrice=0;
    data.map((infoReserva) => (
        totalPax += infoReserva.pax
    ));
    data.map((infoReserva) => (
        totalPrice += infoReserva.price
    ));
    props.passTotalPax(totalPax);
    props.passTotalPrice(totalPrice);
  };

  const resetRadioButtons = () => {
    const radioReset1 = document.getElementById('inline-radio-1');
    const radioReset2 = document.getElementById('inline-radio-2');
    const radioReset3 = document.getElementById('inline-radio-3');
    const radioReset4 = document.getElementById('inline-radio-4');
    const radioReset5 = document.getElementById('inline-radio-5');
    radioReset1.checked = false;
    radioReset2.checked = false;
    radioReset3.checked = false;
    radioReset4.checked = false;
    radioReset5.checked = false;
  };

  const refreshTablaCalendar = () => {
    setModalMedShow(false);
    getReservas();
  };

  const closeModal = () => {
    setModalMedShow(false);
    getReservas();
  }



  /* Getting reservas from selected date */

  return (
    <React.Fragment>
      <ModalMed
        reservaSelected={reservaInfo}
        totalPrice={totalPrice}
        setTotalPrice={setTotalPrice}
        pendingPrice={pendingPrice}
        setPendingPrice={setPendingPrice}
        statusBooking={statusBooking}
        setStatusBooking={setStatusBooking}
        historialPagos={historialPagos}
        setHistorialPagos={setHistorialPagos}
        show={modalMedShow}
        onHide={() => refreshTablaCalendar()}
        title="infoReserva"
        closeModal={closeModal}
      />

      <Carrusel
        func={carrouselDateFilter}
        num="hiii"
        passReservas={props.passReservas}
        passAllReservas={props.passAllReservas}
        passAnotaciones={"test"}
        tableSelected="simple"
      />

      {/* <p onClick={() => carrouselDateFilter()}>TEST</p> */}

      <div className={styles.checksAndSearch}>
        <div className={styles.calendarDisplayContainer}>
          <div className={styles.checkBoxContainer}>
            <Form>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check
                    value="Pagado"
                    inline
                    label="Pagado"
                    name="grup1"
                    type={type}
                    id={`inline-${type}-1`}
                    className={styles.checkbox}
                    onClick={(e) => checkBoxFilter(e)}
                  />
                  <Form.Check
                    value="Parcial"
                    inline
                    label="Parcial"
                    name="grup1"
                    type={type}
                    id={`inline-${type}-2`}
                    className={styles.checkbox}
                    onClick={(e) => checkBoxFilter(e)}
                  />
                  <Form.Check
                    value="No pagado"
                    inline
                    label="No pagado"
                    name="grup1"
                    type={type}
                    id={`inline-${type}-3`}
                    className={styles.checkbox}
                    onClick={(e) => checkBoxFilter(e)}
                  />
                  <Form.Check
                    value="Borrador"
                    inline
                    label="Borrador"
                    name="grup1"
                    type={type}
                    id={`inline-${type}-4`}
                    className={styles.checkbox}
                    onClick={(e) => checkBoxFilter(e)}
                  />
                  <Form.Check
                    value=""
                    inline
                    label="Reset"
                    name="grup1"
                    type={type}
                    id={`inline-${type}-5`}
                    className={styles.checkbox}
                    onClick={(e) => checkBoxFilter(e)}
                  />
                </div>
              ))}
            </Form>
          </div>
        </div>
        <div className={styles.searchBarContainer}>
          <input
            placeholder=" Search..."
            value={searchValue}
            onInput={(e) => handleFilter(e)}
            className={styles.searchBarInput}
          />
        </div>
      </div>

      <Card className={styles.Card}>
        <Table hover className={styles.tableContainer}>
          <thead>
            <tr>
              <th>Heure</th>
              <th>Activité</th>
              <th>Info. réservation</th>
              <th></th>
              <th>ID</th>
              <th>Pers.</th>
              <th>Montant</th>
              <th>Partenaire</th>
              <th>Date réservation</th>
              <th>Le client est-il arrivé ?</th>
            </tr>
          </thead>
          <tbody>
            {currentPosts.map((reserva,index) => {
              return (
                <tr key={index} value={reserva.id}>
                  <td onClick={() => showReservaInfo(reserva.id)}>
                    {reserva.created_hour}
                  </td>
                  <td onClick={() => showReservaInfo(reserva.id)}>
                    {reserva.activity}
                  </td>
                  <td onClick={() => showReservaInfo(reserva.id)}>
                    {reserva.client_info}
                  </td>
                  <td onClick={() => showReservaInfo(reserva.id)}>
                    <span
                      className={
                        reserva.status === "No pagado"
                          ? styles.dotNoPagado
                          : reserva.status === "Parcial"
                          ? styles.dotParcial
                          : reserva.status === "Pagado"
                          ? styles.dotActive
                          : ""
                      }
                    ></span>
                    {reserva.status}
                  </td>
                  <td onClick={() => showReservaInfo(reserva.id)}>
                    {reserva.identifier}
                  </td>
                  <td onClick={() => showReservaInfo(reserva.id)}>
                    {reserva.pax}
                  </td>
                  <td onClick={() => showReservaInfo(reserva.id)}>
                    {reserva.price + " €"}
                  </td>
                  <td onClick={() => showReservaInfo(reserva.id)}>
                    {reserva.channel}
                  </td>
                  <td onClick={() => showReservaInfo(reserva.id)}>
                    {reserva.created_date}
                  </td>
                  <td>
                    {reserva.client_arrived}
                    <p
                      onClick={() => haLlegadoClienteCheck(reserva.identifier)}
                    >
                      {reserva.client_arrived ? (
                        <i
                          className={`fa-regular fa-square-check ${styles.yesCheck}`}
                        ></i>
                      ) : (
                        <i
                          className={`fa-sharp fa-solid fa-xmark ${styles.noCheck}`}
                        ></i>
                      )}
                    </p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>

        <div className={styles.selectContainer}>
          <select
            className={styles.paginationSelect}
            id="pagination"
            onChange={(e) => setPostsPerPage(e.target.value)}
          >
            <option>25</option>
            <option>50</option>
            <option>75</option>
            <option>100</option>
          </select>
        </div>
      </Card>
    </React.Fragment>
  );
}
