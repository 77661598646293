/* eslint-disable */

import axios from "axios";
import React, { useState } from "react";
import { useImperativeHandle } from "react";
import { useEffect } from "react";
import Form from "react-bootstrap/Form";

//UI
import styles from "./datosPersonales.module.css";

function DatosPersonales({
  passPersonalData,
  restartFiltro,
  passDisabledInfo,
},ref) {
  useEffect(() => {
    getCenterCanals();
    setDisabled(true);
  }, []);

  useImperativeHandle(ref, () => ({
    childFunction: (dato) => {
      validateFields(dato)
    }
  }))

  var selectedChanel = "";
  var selectedName = "";
  var selectedSurname = "";

  const [chanelSelected, setChanelSelected] = useState('0')
  const [nameSelected, setNameSelected] = useState('0')
  const [surnameSelected, setSurnameSelected] = useState('0')

  const validateFields = (dato) => {
    if (dato) {
      setChanelSelected(selectedChanel.value)
      setNameSelected(selectedName.value)
      setSurnameSelected(selectedSurname.value)
    }
  }

  const activeCenterObj = JSON.parse(localStorage.getItem("activeCenter"));

  //get active center
  const [centerCanals, setCenterCanals] = useState([]);

  //get canals from active center
  const getCenterCanals = () => {
    axios
      .get(
        process.env.REACT_APP_FHIOS_API_URL + "sales_channel_list?centerId=" +
          activeCenterObj.id
      )

      .then((res) => {
        setCenterCanals(res.data.data);
        console.log(res.data.data);
      });
  };

  const [disabled, setDisabled] = useState(true);
  passDisabledInfo(disabled);

  const [canalValue, setCanalValue] = useState("");
  const [canalName, setCanalName] = useState("");
  const [canalDeposit, setCanalDeposit] = useState("");

  const handleCanalValue = (e) => {
    setDisabled(true);
    setCanalValue(e.target.value);
    if (e.target.value != "" && nameValue != "" && surnameValue != "") {
      setDisabled(false);
      validateFields(true);
    } else {
      setDisabled(true);
      validateFields(true);
    }
    setCanalName(
      [].slice.call(e.target.selectedOptions).map((a) => {
        return a.getAttribute("canalname");
      })
    );
    setCanalDeposit(
      [].slice.call(e.target.selectedOptions).map((a) => {
        return a.getAttribute("canaldeposit");
      })
    );

    return restartFiltro();
  };

  const [nameValue, setNameValue] = useState("");
  const handleNameVal = (e) => {
    setNameValue(e);
    if (e != "" && canalValue != "" && surnameValue != "") {
      setDisabled(false);
      validateFields(true);
    } else {
      setDisabled(true);
      validateFields(true);
    }
  };

  const [surnameValue, setSurnameValue] = useState("");
  const handleSurnameVal = (e) => {
    setSurnameValue(e);
    if (e != "" && nameValue != "" && canalValue != "") {
      setDisabled(false);
      validateFields(true);
    } else {
      setDisabled(true);
      validateFields(true);
    }
  };

  const [phoneValue, setPhoneValue] = useState("");
  const handlePhoneValue = (e) => {
    setPhoneValue(e);
  };

  const [emailValue, setEmailValue] = useState("");
  const handleEmailValue = (e) => {
    setEmailValue(e);
  };

  const [dniValue, setDniValue] = useState("");
  const handleDniValue = (e) => {
    setDniValue(e);
  };

  let datosPersonalesData = {
    channel_id: canalValue,
    channel_name: canalName,
    center_id: activeCenterObj.id,
    client_name: nameValue,
    client_surname: surnameValue,
    client_phone: phoneValue,
    client_mail: emailValue,
    client_dni: dniValue,
    canal_deposit: canalDeposit,
  };

  passPersonalData(datosPersonalesData);

  return (
    <React.Fragment>
      <div className={styles.container}>
        <Form.Group controlId="custom-select" className="mb-3">
          <Form.Label className="mb-3">
            Canal <span className={styles.requiredFieldStar}>*</span>
          </Form.Label>
          <Form.Control
            as="select"
            className={`form-select ${styles.inputs} ${chanelSelected === '' ? 'error' : '' }`}
            value={canalValue}
            onChange={(e) => handleCanalValue(e)}
            ref={(input) => selectedChanel = input}
          >
            <option value="">Selecciona canal de compra</option>
            {centerCanals.map((canal) => {
              return (
                <option
                  key={canal.id}
                  value={canal.id}
                  canaldeposit={canal.deposit}
                  canalname={canal.title}
                >
                  {canal.title}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
        <div className={styles.formGroup}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className={styles.labels}>
              {" "}
              Prénom <span className={styles.requiredFieldStar}>*</span>
            </Form.Label>
            <Form.Control
              // disabled={disabled}
              type="text"
              placeholder=""
              value={nameValue}
              className={`${styles.inputs} ${nameSelected === '' ? 'error' : '' }`}
              onChange={(e) => handleNameVal(e.target.value)}
              ref={(input) => selectedName = input}
            />
          </Form.Group>
        </div>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className={styles.labels}>
            Nom <span className={styles.requiredFieldStar}>*</span>
          </Form.Label>
          <Form.Control
            // disabled={disabled}
            type="text"
            placeholder=""
            value={surnameValue}
            className={`${styles.inputs} ${surnameSelected === '' ? 'error' : '' }`}
            onChange={(e) => handleSurnameVal(e.target.value)}
            ref={(input) => selectedSurname = input}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className={styles.labels}>Móvil</Form.Label>
          <Form.Control
            // disabled={disabled}
            type=""
            placeholder=""
            value={phoneValue}
            className={styles.inputs}
            onChange={(e) => handlePhoneValue(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className={styles.labels}>Mail</Form.Label>
          <Form.Control
            // disabled={disabled}
            type="email"
            placeholder=""
            value={emailValue}
            className={styles.inputs}
            onChange={(e) => handleEmailValue(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className={styles.labels}>DNI</Form.Label>
          <Form.Control
            // disabled={disabled}
            type="text"
            placeholder=""
            value={dniValue}
            className={styles.inputs}
            onChange={(e) => handleDniValue(e.target.value)}
          />
        </Form.Group>
      </div>
    </React.Fragment>
  );
}

export default React.forwardRef(DatosPersonales);
