import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button } from "antd";
import BasicLayout from "../../layouts/BasicLayout";
import "./error.css";

export default function BadPermissions() {

  let navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
  }

  function importAll(r) {
    let images = {}
    r.keys().map((item, index) => { images[item.replace('./', '').split('.').shift()] = r(item); })
    return images
  }

  const images = importAll(require.context('../Images', false, /\.(png|jpe?g|svg)$/))

  return (
    <BasicLayout>
      <Card style={{textAlign: 'center'}}>
        <h4>Lo sentimos, no tienes permisos para acceder a esta página</h4>
        <div style={{display: 'grid', placeContent: 'center', margin: "20px 0"}}>
          <img style={{maxHeight: 200}} src={images.deniedAccess} />
        </div>
        <Button className="main-btn" onClick={() => goBack()}>Retour</Button>
      </Card>
    </BasicLayout>
  )
  
}