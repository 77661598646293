import React, { useState, useEffect } from "react"
import { Modal, Input, InputNumber, Form, Select, Button, Row, Col } from "antd"
import apiManager from "../../api/apiManager"
import { useUser } from "../../api/context/userContext"

export default function InvoiceRect(props) {

  const { userData } = useUser()
  const [totalPaid, setTotalPaid] = useState(0)
  const [paymentOperator, setPaymentOperator] = useState('minus')
  const [channels, setChannels] = useState([])
  const [clients, setClients] = useState([])
  const [pm, setPm] = useState([])
  const [invoice, setInvoice] = useState([])
  const [formValues, setFormValues] = useState({})
  //const [selectedChannel, setSelectedChannel] = useState(null)
  //const [selectedClient, setSelectedClient] = useState(null)
  const [formRect] = Form.useForm()
  const { Option } = Select
  const { TextArea } = Input

  let selectedChannel = null
  let selectedClient = null

  useEffect(() => {
    if (props.open) {
      getChannels()
      getClients()
      setInvoice([])
      setPm([])
    }
  }, [props.open])

  const getChannels = () => {
    setChannels([])
    apiManager.getChannels(JSON.parse(localStorage.getItem("activeCenter")).id, userData.is_b2b, userData.user_token)
    .then((res) => {
      let result = res.data.data
      result.map((channel) => {
        setChannels(current => [...current, {
          'label': `${channel.title} (${channel.social_reason})`,
          'value': channel.id,
        }])
      })
    })
  }

  const getClients = () => {
    setClients([])
    apiManager.getClientFact(JSON.parse(localStorage.getItem("activeCenter")).id)
    .then((res) => {
      let result = res.data.data
      result.map((client) => {
        setClients(current => [...current, {
          'label': client.name,
          'value': client.client_id,
        }])
      })
    })
  }

  const getFacts = (formFields) => {
    setInvoice([])
    apiManager.getFactByScCl(formFields.channel, formFields.client)
    .then((res) => {
      let result = res.data.data
      result.map((invoice) => {
        setInvoice(current => [...current, {
          'label': invoice.invoice_id,
          'value': invoice.invoice_id
        }])
      })
    })
  }

  const checkFields = (value, field) => {
    let formFields = formRect.getFieldsValue()
    if (field === 'channel') {
      setPm([])
      apiManager.getPaymentMethods(
        formFields.channel,
        JSON.parse(localStorage.getItem("activeCenter")).id,
        false
      )
      .then((res) => {
        let result = res.data.data
        result.map((pm) => {
          setPm(current => [...current, {
            'label': pm.title,
            'value': pm.id
          }])
        })
      })
    }
    setFormValues(formFields)
    if (formFields.channel !== undefined && formFields.client !== undefined) {
      if (formFields.invoice !== undefined) {
        formRect.setFieldValue('invoice', undefined)
      }
      getFacts(formFields)
    }
  }

  const changeFact = (value) => {
    let formFields = formRect.getFieldsValue()
    setFormValues(formFields)
  }

  const resetForm = () => {
    formRect.resetFields()
  }

  const closeModal = (reload) => {
    resetForm()
    props.closeInvoiceRectFunc(reload)
  }

  const paymentChange = (changed, formValues) => {
    if (formValues.amount !== 0 && formValues.amount !== null) {
      setTotalPaid(formValues.amount)
    } else {
      setTotalPaid(0)
    }
  }

  const changeOperator = (value) => {
    setPaymentOperator(value)
  }

  const createFactRect = () => {
    let formFields = formRect.getFieldsValue()
    let data = {
      invoice_id: formFields.invoice,
      information: formFields.concept === undefined ? "" : formFields.concept,
      import: paymentOperator === "minus" ? -Math.abs(formFields.amount) : formFields.amount,
      iva: formFields.iva,
      pm_id: formFields.pm
    }
    apiManager.invoiceRect(data)
    .then((res) => {
      let result = res.data.data
      resetForm()
      closeModal(true)
    })
  }

  const selectBefore = (
    <Select
      value={paymentOperator}
      style={{
        width: 50,
      }}
      onChange={(e) => changeOperator(e)}
    >
      <Option value="add">+</Option>
      <Option value="minus">-</Option>
    </Select>
  );

  return (
    <Modal
      onCancel={() => closeModal(undefined)}
      centered={true}
      open={props.open}
      width={800}
      destroyOnClose={true}
      footer={null}
    >
      <Form
        form={formRect}
        //labelCol={{ span: 8 }}
        //wrapperCol={{ span: 14 }}
        style={{marginTop: 30}}
        onValuesChange={(a, e) => paymentChange(a, e)}
        onFinish={createFactRect}
        layout="vertical"
      >
        <Row>
          <Col span={11}>
            <Form.Item
              label={"Canal"}
              name="channel"
              rules={[
                {
                  required: true,
                  message: "Champ obligatoire"
                }
              ]}
            >
              <Select
                showSearch
                options={channels}
                onChange={(e, a) => checkFields(a, 'channel')}
                placeholder="Sélectionner partenaire"
                filterOption={(input, option) =>
                  (option.label).toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item
              label={"Cliente"}
              name="client"
              rules={[
                {
                  required: true,
                  message: "Champ obligatoire"
                }
              ]}
            >
              <Select
                showSearch
                options={clients}
                onChange={(e, a) => checkFields(a, 'client')}
                placeholder="Selecciona un cliente"
                filterOption={(input, option) =>
                  (option.label).toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item
              label={"Factura"}
              name="invoice"
              rules={[
                {
                  required: true,
                  message: "Champ obligatoire"
                }
              ]}
            >
              <Select
                showSearch
                disabled={formValues.client === undefined || formValues.channel === undefined}
                options={invoice}
                onChange={(e) => changeFact(e)}
                placeholder="Selecciona una factura"
                filterOption={(input, option) =>
                  (option.label).toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
          <Col span={11} offset={2}>
            <Form.Item
              label={"Método de pago"}
              name="pm"
              rules={[
                {
                  required: true,
                  message: "Champ obligatoire"
                }
              ]}
            >
              <Select
                showSearch
                disabled={formValues.invoice === undefined}
                options={pm}
                //onChange={(e, a) => checkFields(a, 'client')}
                placeholder="Choisissez une méthode de paiement"
                filterOption={(input, option) =>
                  (option.label).toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item
              label={"Importe Total"}
              name="amount"
              initialValue={0}
              rules={[
                {
                  type: 'number',
                  min: 1,
                  message: "El valor no puede ser 0"
                },
              ]}
            >
              <InputNumber
                disabled={formValues.invoice === undefined}
                style={{width: '100%'}}
                addonBefore={selectBefore}
                addonAfter="€"
                min={0}
              />
            </Form.Item>
            <Form.Item
              label={"IVA"}
              name="iva"
              initialValue={0}
              rules={[
                {
                  required: true,
                  message: "Champ obligatoire"
                },
              ]}
            >
              <InputNumber
                disabled={formValues.invoice === undefined}
                style={{width: '100%'}}
                addonAfter="%"
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={"Concepto"}
              name="concept"
              /* rules={[
                {
                  required: true,
                  message: "Champ obligatoire"
                }
              ]} */
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          labelCol={{ span: 0 }}
          wrapperCol={{ span: 24 }}
        >
          <Button
            className="main-btn"
            type="primary"
            htmlType="submit"
            style={{margin: 'auto', display: 'block'}}
          >Generar rectificación</Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}