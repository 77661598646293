import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import { useNavigate, useLocation } from 'react-router-dom';
import apiManager from "../api/apiManager";

import {
  FileTextOutlined,
  BarChartOutlined
} from '@ant-design/icons';
import { DownhillSkiing, ManageSearch, Dashboard } from "@mui/icons-material";

export default function B2BMainMenu (props) {

  const { Sider } = Layout
  const [collapsed, setCollapsed] = useState(localStorage.getItem("menuCollapsed") === 'false' ? false : true)
  const [currentPage, setCurrentPage] = useState([])
  const [isBilling, setIsBilling] = useState(false)
  const [isSubBilling, setIsSubBilling] = useState(false)
  let navigate = useNavigate()
  let location = useLocation()

  useEffect(() => {
    apiManager.isBillingAdmin(localStorage.getItem("token"))
    .then((res) => {
      let result = res.data.data[0]
      setIsBilling(result.user_is_billing_admin)
    })
    apiManager.isSubBillingAdmin(localStorage.getItem("token"))
    .then((res) => {
      let result = res.data.data[0]
      setIsSubBilling(result.user_is_sub_billing_admin)
    })
  }, [])

  useEffect(() => {
    setCurrentPage(location.pathname)
  }, [location])

  function getItem(label, key, icon, children, className) {
    return {
      key,
      icon,
      children,
      label,
      className,
    };
  }

  const reports = isBilling || isSubBilling ?
    getItem('Informes', '/informes', <BarChartOutlined />, [
      isBilling ? getItem('Ventes mensuelles', '/produc-mensual') : "",
      isBilling ? getItem('Ventes par partenaire', '/produc-canal') : "",
      isSubBilling ? getItem('Caisse', '/cierres-caja') : "",
      isBilling ? getItem('Ventes par activité', '/informe-actividades') : "",
    ])
  : ""
  
  const items = [
    //getItem('Dashboard', '/b2b/dashboard', <Dashboard />),
    getItem('Reservas', '/b2b/reservas', <FileTextOutlined />),
    getItem('Recherche réserv.', '/b2b/reservas-buscador', <ManageSearch />),
    //reports,
    /* getItem('Informes', '/informes', <BarChartOutlined />, [
      getItem('Ventes mensuelles', '/produc-mensual'),
      getItem('Ventes par partenaire', '/produc-canal'),
      getItem('Caisse', '/cierres-caja'),
      getItem('Ventes par activité', '/informe-actividades')
    ], 'reports-item'), */
  ]

  const updateCollapsed = (value) => {
    localStorage.setItem("menuCollapsed", !collapsed)
    setCollapsed(!collapsed)
    props.changeCollapsed(!collapsed)
  }

  const handleClick = (item, key, keyPath) => {
    navigate(item.key)
  }

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => updateCollapsed(value)}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        zIndex: 10,
        paddingTop: 100,
        boxShadow: "1px 0px 10px #00000026"
      }}
    >
      <Menu theme="dark" selectedKeys={currentPage} mode="inline" items={items} onClick={(item, key, keyPath) => handleClick(item, key, keyPath)} />
    </Sider>
  )

}