import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import apiManager from "../../api/apiManager";
import { Form, Input, Button, notification, Card } from "antd";
import { CircleSpinnerOverlay } from "react-spinner-overlay";
import BasicLayout from "../../layouts/BasicLayout";

import { useUser } from "../../api/context/userContext";

import "./newauth.css";

export default function NewAuth(props) {

  const { getUserData } = useUser()
  const userToken = localStorage.getItem("token")
  const navigate = useNavigate()
  let location = useLocation()

  const [show, setShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (userToken !== null) {
      if (location.pathname === "/") {
        navigate("/reservas")
      } else if (location.pathname === "/informes") {
        navigate("/produc-mensual")
      }
    } else {
      setShow(true)
    }
  }, [])

  const loginCall = (values) => {
    setIsLoading(true)
    let data = {
      email: values.username,
      pwd: values.password
    }
    apiManager.loginCall(data)
    .then((res) => {
      let result = res.data
      if (result.message_error !== "") {
        messageError(result.message_error)
        setIsLoading(false)
      } else {
        localStorage.setItem('token', result.data[0].user_token)
        const activeCenter = result.data[0].active_center
        const userCenters = result.data[0].centers
        localStorage.setItem("userCenters", JSON.stringify(userCenters))
        localStorage.setItem("activeCenter", JSON.stringify(activeCenter))
        localStorage.setItem("isb2b", result.data[0].is_b2b)
        setIsLoading(false)
        getUserData()
        props.refreshScreen(result.data[0].is_b2b)
      }
    })
  }

  const messageError = (error) => {
    console.log(error)
    switch (error) {
      case '__user.not.exists':
        notification.error({
          key: 'error',
          placement: 'bottom',
          duration: 8,
          message: 'L´email saisi n´existe pas'
        })
        break;
      case '__user.pwd.not.match':
        notification.error({
          key: 'error',
          placement: 'bottom',
          duration: 8,
          message: 'Le mot de passe est incorrect'
        })
        break
      default:
        notification.error({
          key: 'error',
          placement: 'bottom',
          duration: 4,
          message: 'Erreur lors de la tentative de connexion'
        })
        break;
    }
  }

  function importAll(r) {
    let images = {}
    r.keys().map((item, index) => { images[item.replace('./', '').split('.').shift()] = r(item); })
    return images
  }

  const images = importAll(require.context('../Images', false, /\.(png|jpe?g|svg)$/))

  return (
    <>
      <CircleSpinnerOverlay
        color="#f56457"
        outerBorderWidth="6"
        outerBorderOpacity="0.3"
        overlayColor="#00000075"
        loading={isLoading}
      />
      {
        show ?
          <BasicLayout>
            <Card style={{width: 400}}>
              <div className="logo-auth">
                <img src={images.logo} style={{width: 220}} />
              </div>
              <Form
                onFinish={loginCall}
              >
                <Form.Item
                  label="Email"
                  labelCol={{span: 8}}
                  name="username"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Entrer un email valide",
                    },
                  ]}
                >
                  <Input placeholder="Correo electrónico" />
                </Form.Item>
                <Form.Item
                  label="Mot de passe"
                  labelCol={{span: 8}}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Champ obligatoire',
                    },
                  ]}
                >
                  <Input.Password placeholder="Contraseña" />
                </Form.Item>
                <Form.Item style={{textAlign: 'center', marginBottom: 0}}>
                  <Button type="primary" htmlType="submit" className="main-btn">
                    Commencer la session
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </BasicLayout>
        : <></>
      }
    </>
  )
}