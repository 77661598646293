import React, { useState, useEffect } from "react";
import { Row, Col, Divider, Tabs, Collapse, Button, Modal, Form, Select, Input, InputNumber, DatePicker, Tag, Badge, Checkbox, Tooltip, notification } from "antd";
import apiManager from "../../api/apiManager";
import { FormOutlined, DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import moment from "moment";
import Loader from "../Loader/Loader";

import { useUser } from "../../api/context/userContext";

//confirm dialog
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

import "./bookingresume.css";
import { Description, Person } from "@mui/icons-material";

export default function BookingResume(props) {

  //console.log(props.data)
  const { userData } = useUser()

  const [channels, setChannels] = useState([])
  const [channelSelected, setChannelSelected] = useState({})
  const [optionTypes, setOptionsType] = useState([])
  const [optionsPayment, setOptionsPayment] = useState([])
  const [aditionalServices, setAditionalServices] = useState([])
  const [isModalServices, setIsModalServices] = useState(false)
  const [isModalTotal, setIsModalTotal] = useState(false)
  const [isModalAnnotation, setIsModalAnnotation] = useState(false)
  const [isModalInvoice, setIsModalInvoice] = useState(false)
  const [serviceEdit, setServiceEdit] = useState({edit: false, index: -1})
  const [sumActivitiesPrice, setSumActivitiesPrice] = useState(0)
  const [sumActivitiesPricePvP, setSumActivitiesPricePvP] = useState(0)
  const [sumServicesPrice, setSumServicesPrice] = useState(0)
  const [totalPaid, setTotalPaid] = useState(0)
  const [depositChannel, setDepositChannel] = useState(0)
  const [newTotal, setNewTotal] = useState(0)
  const [notesList, setNotesList] = useState([])
  const [invoice, setInvoice] = useState(false)
  const [discountOptions, setDiscountOption] = useState([])
  const [dataInvoice, setDataInvoice] = useState({})
  const [finishDisable, setFinishDisable] = useState(false)
  const [bloqued, setBloqued] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [form] = Form.useForm()
  const [formTotal] = Form.useForm()
  const [formPayment] = Form.useForm()
  const [formNotes] = Form.useForm()
  const [formFac] = Form.useForm()

  useEffect(() => {
    if (props.personal.channel !== null) {
      apiManager.getChannels(
        JSON.parse(localStorage.getItem("activeCenter")).id,
        userData.is_b2b ? userData.is_b2b : undefined,
        userData.is_b2b ? userData.user_token : undefined
      )
      .then((res) => {
        res.data.data.map((channel) => {
          setChannels(current => [...current, {
            'value': channel.id,
            'label': channel.title,
            'deposit': channel.deposit
          }])
          //setChannelsLoaded(true)
        })
      })
    }
    apiManager.getServicesTypes(JSON.parse(localStorage.getItem("activeCenter")).id)
    .then((res) => {
      res.data.data.map((type) => {
        setOptionsType(current => [...current, {
          'value': type.id,
          'label': type.title
        }])
      })
    })
    apiManager.getListPromotions()
    .then((res) => {
      let promotionsResult = res.data.data
      setDiscountOption([])
      promotionsResult.map((promotion) => {
        setDiscountOption(current => [...current, {
          'value': promotion.id,
          'label': promotion.name,
          'default': promotion.default
        }])
      })
    })
  }, [])

  useEffect(() => {
    //console.log(props)
    if (channelSelected.id !== undefined) {
      if (channelSelected.id !== props.personal.channel) {
        setChannelSelected({
          id: props.personal.channel,
          title: channels.find(o => o.value === props.personal.channel).label
        })
        setDepositChannel(channels.find(o => o.value === props.personal.channel).deposit)
        getPaymentOptions(props.personal.channel, JSON.parse(localStorage.getItem("activeCenter")).id)
      }
    } else {
      if ((props.personal.channel !== null && props.personal.channel !== undefined) && props.personal.name !== "") {
        setChannelSelected({
          id: props.personal.channel,
          title: channels.find(o => o.value === props.personal.channel).label
        })
        setDepositChannel(channels.find(o => o.value === props.personal.channel).deposit)
        getPaymentOptions(props.personal.channel, JSON.parse(localStorage.getItem("activeCenter")).id)
      }
    }
    setSumActivitiesPrice(0)
    setSumActivitiesPricePvP(0)
    props.data.forEach(element => {
      element.activities.forEach(activity => {
        activity.finishRates.forEach(rates => {
          setSumActivitiesPrice(current => current + rates.ratePrice)
          if (userData.is_b2b) {
            setSumActivitiesPricePvP(current => current + rates.ratePricePvP)
          }
        })
      })
    });
  }, [props])

  useEffect(() => {
    if (optionsPayment.length === 1) {
      formPayment.setFieldValue('method', optionsPayment[0].value)
    }
  }, [optionsPayment])

  useEffect(() => {
    setSumServicesPrice(aditionalServices.reduce((accumulator, object) => {return accumulator + object.price;}, 0))
  }, [aditionalServices])

  const { TabPane } = Tabs
  const { Panel } = Collapse
  const { TextArea } = Input

  const getPaymentOptions = (channelId, centerId) => {
    setOptionsPayment([])
    apiManager.getPaymentMethods(channelId, centerId, true)
    .then((res) => {
      res.data.data.map((type) => {
        setOptionsPayment(current => [...current, {
          'value': type.id,
          'label': type.title
        }])
      })
    })
  }

  const closeServicesModal = () => {
    form.resetFields()
    setIsModalServices(false)
    setServiceEdit({edit: false, index: -1})
  }

  const closeTotalModal = () => {
    //formTotal.resetFields()
    setIsModalTotal(false)
  }

  const closeAnnotationModal = () => {
    setIsModalAnnotation(false)
  }

  const addNewService = (values) => {
    console.log(values)
    if (!serviceEdit.edit) {
      setAditionalServices(current => [...current, {
        type: values.type,
        description: values.description,
        price: values.price === undefined ? 0 : values.price
      }])
      closeServicesModal()
    } else {
      let items = aditionalServices
      let item = items[serviceEdit.index]
      item = {type: values.type, description: values.description, price: values.price === undefined ? 0 : values.price}
      items[serviceEdit.index] = item
      setAditionalServices(items);
      closeServicesModal()
    }
  }

  const deleteNewService = (index) => {
    var newListado = [...aditionalServices];
    newListado.splice(index, 1);
    setAditionalServices(newListado);
  }

  const editNewService = (service, index) => {
    setServiceEdit({edit: true, index: index})
    form.setFieldValue('type', service.type)
    form.setFieldValue('description', service.description)
    form.setFieldValue('price', service.price)
    setIsModalServices(true)
    console.log(service)
  }

  const addNewPrice = (value) => {
    if (value.price === null) {
      setNewTotal(0)
    } else {
      setNewTotal(value.price)
    }
    closeTotalModal()
  }

  const paymentChange = (changed, formValues) => {
    if (formValues.method !== undefined && (formValues.amount !== 0 && formValues.amount !== null)) {
      setTotalPaid(formValues.amount)
    } else {
      setTotalPaid(0)
    }
  }

  const addNote = (values) => {
    console.log(values)
    setNotesList(current => [...current, {
      'created_date': moment(Date.now()).format('DD/MM/YYYY - H:mm') + 'h',
      'comment': values.note
    }])
    formNotes.resetFields()
  }

  const deleteAnotacion = (index) => {
    var newListado = [...notesList];
    newListado.splice(index, 1);
    setNotesList(newListado);
  }

  const confirmRemoveService = (index) => {
    confirmAlert({
      title: 'Voulez-vous supprimer ce service ?',
      buttons: [
        {
          label: 'Confirmer',
          onClick: () => deleteNewService(index),
          className: 'confirm-btn',
        },
        {
          label: 'Annuler',
          className: 'cancel-btn',
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-confirm-suprimir"
    })
  }

  const changeInvoice = (value) => {
    //console.log(value.target.checked)
    setInvoice(value.target.checked)
    //setIsModalInvoice(true)
  }

  const invoiceData = (data) => {
    setDataInvoice({
      socialReason: data.socialReason,
      fiscalEmail: data.fiscalEmail,
      fiscalNif: data.fiscalNif,
      fiscalAddress: data.fiscalAddress,
      cp: data.postalCode,
      city: data.city,
      country: data.country
    })
    setIsModalInvoice(false)
    //console.log(data)
  }

  const cancelInvoice = () => {
    if (dataInvoice.socialReason === undefined) {
      formFac.setFieldValue("socialReason", undefined)
      formFac.setFieldValue("fiscalEmail", undefined)
      formFac.setFieldValue("fiscalNif", undefined)
      formFac.setFieldValue("fiscalAddress", undefined)
      formFac.setFieldValue("postalCode", undefined)
      formFac.setFieldValue("city", undefined)
      formFac.setFieldValue("country", undefined)
      //setInvoice(false)
      setIsModalInvoice(false)
      /* notification.warning({
        key: 'warning',
        placement: 'bottom',
        duration: 2,
        message: `La facture du client a été annulée`
      }) */
    } else {
      //console.log(dataInvoice)
      setIsModalInvoice(false)
    }
  }

  const finishBooking = () => {
    setFinishDisable(true)
    setIsLoading(true)

    let joinActivities = []

    props.data.map((day) => {
      day.activities.map((activity) => {
        activity.dateActivity = day.date
        if (activity.formValues.resources === undefined) {
          activity.formValues.resources = []
        }
        joinActivities.push(activity)
      })
    })

    let bookingFinalResume = {
      channel_id: props.personal.channel,
      center_id: JSON.parse(localStorage.getItem("activeCenter")).id,
      user_id: localStorage.getItem('token'),
      client_name: props.personal.name,
      client_surname: props.personal.surname,
      client_phone: props.personal.phone === null || props.personal.phone === undefined ? "" : props.personal.phone,
      client_mail: props.personal.mail === null || props.personal.mail === undefined ? "" : props.personal.mail,
      client_dni: props.personal.dni === null || props.personal.dni === undefined ? "" : props.personal.dni,
      internal_ref: props.personal.refInt === null || props.personal.refInt === undefined ? "" : props.personal.refInt,
      start_date: props.personal.dates[0],
      end_date: props.personal.dates[props.personal.dates.length - 1],
      booking_activities: joinActivities.map((activity) => {
        return {
          id: activity.formValues.activity,
          start_date: activity.dateActivity,
          end_date: activity.dateActivity,
          tarifas: activity.finishRates.map((rate) => {
            return {
              tarifa_id: rate.resumeRate.value,
              occupation: rate.units,
              price: rate.ratePrice / rate.units,
              promotion: rate.discountType,
              base_price: rate.basePrice,
            }
          }),
          num_pax: activity.persons,
          price_booking: activity.finishRates.reduce((accumulator, object) => {return accumulator + object.ratePrice;}, 0),
          turn_id: activity.formValues.turns,
          resource_occupation: activity.formValues.resources.map((resource) => {
            return {
              id: activity.byUnitMode ? resource : resource.value,
              resource_id: activity.byUnitMode ? resource : resource.value,
              occupation: activity.byUnitMode ? "1" : resource.inputValue.toString(),
              remove: false,
            }
          }),
        }
      }),
      price: newTotal !== 0 && sumActivitiesPrice + sumServicesPrice !== newTotal ? newTotal : sumActivitiesPrice + sumServicesPrice,
      method_payment_id: formPayment.getFieldValue('method') === undefined ? "" : formPayment.getFieldValue('method'),
      payment_done: (formPayment.getFieldValue('amount') !== null || formPayment.getFieldValue('amount') !== 0) && formPayment.getFieldValue('method') !== undefined ? formPayment.getFieldValue('amount') : "",
      payment_date: dayjs(formPayment.getFieldValue('date')).format('YYYY-MM-DD'),
      anotations_reserva: notesList,
      aditional_services: aditionalServices,
      client_invoice: invoice,
      status_blocked: bloqued
    }

    if (dataInvoice.socialReason !== undefined) {
      bookingFinalResume.client_invoice_data = true
      bookingFinalResume.client_social_reason = dataInvoice.socialReason
      bookingFinalResume.client_fiscal_email = dataInvoice.fiscalEmail
      bookingFinalResume.client_fiscal_nif = dataInvoice.fiscalNif
      bookingFinalResume.client_fiscal_address = dataInvoice.fiscalAddress
      bookingFinalResume.client_cp = dataInvoice.cp
      bookingFinalResume.client_city = dataInvoice.city
      bookingFinalResume.client_country = dataInvoice.country
    }

    console.log(bookingFinalResume)

    apiManager.createNewBooking(bookingFinalResume)
    .then((res) => {
      let data = res.data.data
      console.log(data)
      setIsLoading(false)
      props.closeFinishBooking(props.personal.dates[0], props.personal.channel, data[0].id)
    })
  }

  return (
    <>
      <Loader controller={isLoading} />
      <Modal
        footer={null}
        open={isModalInvoice}
        centered={true}
        maskClosable={false}
        onCancel={() => cancelInvoice()}
        width={600}
        destroyOnClose={true}
      >
        <Form
          form={formFac}
          onFinish={() => invoiceData(formFac.getFieldsValue())}
          style={{marginTop: 15}}
          layout="vertical"
        >
          <Row>
            <Col span={11}>
              <Form.Item
                label="Razón social"
                name="socialReason"
                rules={
                  [{
                    required: true,
                    message: "Champ obligatoire",
                  }]
                }
              >
                <Input placeholder="Añade la razón social" />
              </Form.Item>
            </Col>
            <Col span={11} offset={2}>
              <Form.Item
                label="CIF Facturación"
                name="fiscalNif"
                rules={
                  [{
                    required: true,
                    message: "Champ obligatoire",
                  }]
                }
              >
                <Input placeholder="Añade el NIF de facturación" />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label="Email Facturación"
                name="fiscalEmail"
                rules={
                  [{
                    required: true,
                    message: "Champ obligatoire",
                  }]
                }
              >
                <Input placeholder="Añade el email de facturación" />
              </Form.Item>
            </Col>
            <Col span={11} offset={2}>
              <Form.Item
                label="Dirección fiscal"
                name="fiscalAddress"
                rules={
                  [{
                    required: true,
                    message: "Champ obligatoire",
                  }]
                }
              >
                <Input placeholder="Añade la dirección fiscal" />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label="Código postal"
                name="postalCode"
                rules={
                  [{
                    required: true,
                    message: "Champ obligatoire",
                  }]
                }
              >
                <Input placeholder="Añade el código postal" />
              </Form.Item>
            </Col>
            <Col span={11} offset={2}>
              <Form.Item
                label="Ciudad"
                name="city"
                rules={
                  [{
                    required: true,
                    message: "Champ obligatoire",
                  }]
                }
              >
                <Input placeholder="Añade la ciudad" />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label="País"
                name="country"
                rules={
                  [{
                    required: true,
                    message: "Champ obligatoire",
                  }]
                }
              >
                <Input placeholder="Añade un país" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            style={{marginTop: 20}}
          >
            <Button
              className="basic-btn"
              style={{marginRight: 20}}
              onClick={() => cancelInvoice()}
            >Annuler</Button>
            <Button
              className="main-btn"
              htmlType="submit"
            >Guardar</Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        footer={null}
        open={isModalAnnotation}
        centered={true}
        maskClosable={false}
        onCancel={() => closeAnnotationModal()}
        width={700}
        destroyOnClose={true}
      >
        {
          notesList.length > 0 ?
            <div className="listNotes">
              {
                notesList.map((note, index) =>
                  <div className="noteItem" key={`noteItem-${index}`}>
                    <Row align="middle">
                      <Col span={22}>
                        <p><b>Date de l´annotation</b>: { note.created_date }</p>
                        <p><b>Anotación</b>: { note.comment }</p>
                      </Col>
                      <Col span={2} style={{textAlign: "right"}}>
                        <Button
                          className="iconCircle deleteBtn"
                          title="Supprimer l´annotation"
                          onClick={() => deleteAnotacion(index)}
                        ><DeleteOutlined className="white" /></Button>
                      </Col>
                    </Row>
                  </div>
                )
              }
            </div>
          : "" 
        }
        <Form
          form={formNotes}
          onFinish={() => addNote(formNotes.getFieldsValue())}
        >
          <Form.Item
            label="Notes"
            name="note"
            style={{marginTop: 30}}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item style={{display: "flex", justifyContent: "center"}}>
            <Button
              className="basic-btn"
              style={{marginRight: 20}}
              onClick={() => closeAnnotationModal()}
            >Retour</Button>
            <Button
              className="main-btn"
              htmlType="submit"
            >Enregistrer commentaire</Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        //destroyOnClose={true}
        footer={null}
        open={isModalTotal}
        centered={true}
        maskClosable={false}
        onCancel={() => closeTotalModal()}
      >
        <Form
          form={formTotal}
          name="total"
          style={{marginTop: 30, display: "flex", justifyContent: "space-evenly"}}
          onFinish={() => addNewPrice(formTotal.getFieldsValue())}
        >
          <Form.Item
            label="Prix"
            name="price"
            initialValue={0}
            /* rules={[
              {
                required: true,
                message: "Champ obligatoire"
              },
            ]} */
          >
            <InputNumber
              addonAfter="€"
              //defaultValue={0}
              min={0}
            />
          </Form.Item>
          <Form.Item>
            <Button
              className="main-btn"
              style={{display: "block", margin: "auto"}}
              htmlType="submit"
            >Modificar precio</Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        maskClosable={false}
        onCancel={() => closeServicesModal()}
        centered={true}
        open={isModalServices}
        destroyOnClose={true}
        footer={null}
      >
        <Form
          form={form}
          name="services"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          style={{marginTop: 30}}
          onFinish={() => addNewService(form.getFieldsValue())}
        >
          <Form.Item
            label="Tipo:"
            name="type"
            rules={[
              {
                required: true,
                message: "Champ obligatoire"
              },
            ]}
          >
            <Select
              options={optionTypes}
              placeholder="Seleccionner un extra"
            />
          </Form.Item>
          <Form.Item
            label="Description:"
            name="description"
            rules={[
              {
                required: true,
                message: "Champ obligatoire"
              },
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item
            label="Prix:"
            name="price"
            initialValue={0}
          >
            <InputNumber
              addonAfter="€"
              //defaultValue={0}
              //min={0}
            />
          </Form.Item>
          <Form.Item>
            <Button
              className="main-btn"
              style={{display: "block", margin: "auto"}}
              htmlType="submit"
            >{ serviceEdit.edit ? "Modifier le service" : "Enregistrer extra"}</Button>
          </Form.Item>
        </Form>
      </Modal>
      <Row justify="space-between">
        <Col span={6} className="personalData">
          <p><b>Partenaires</b>: { channelSelected.title }</p>
          <p><b>Prénom</b>: { props.personal.name }</p>
          <p><b>Nom</b>: { props.personal.surname }</p>
          <p><b>Email</b>: { props.personal.mail }</p>
          <p><b>Téléphone</b>: { props.personal.phone }</p>
          <p><b>Identifiant fiscal</b>: { props.personal.dni }</p>
        </Col>
        <Col span={17} className="ratesData">
          <Tabs>
            {
              props.data.map((date, index) =>
                <TabPane tab={`Activités du ${date.date} (${date.activities.length})`} key={`tab-${index}`}>
                  <Row justify="space-between">
                  {
                    date.activities.map((activity, index) =>
                      <Col span={11} className="activityData" key={`activityItem-${index}`}>
                        <p><b>Activité</b>: { `${activity.familiesArray.find(o => o.id === activity.formValues.activityFamily).name.split(" - ").pop()} - ${activity.activitiesArray.find(o => o.id === activity.formValues.activity).name.split(' - ').splice(1).join(' - ')}` }</p>
                        <p><b>Participants</b>: { activity.persons }</p>
                        <p><b>Tarifs</b>:</p>
                        <div>
                          {
                            activity.finishRates.map((rate, index) =>
                              !userData.is_b2b ?
                                <p key={`rateItem-${index}`}><b>Tarif</b>: { rate.resumeRate.name.split(" - ").pop() } - <b>Prix</b>: { rate.ratePrice === (rate.basePrice * rate.units) ? rate.ratePrice : <Tooltip title={`Rabais: ${discountOptions.find(o => o.value === rate.discountType).label.split('-').at(-1)}`}><del style={{color: "red"}}>{ rate.basePrice * rate.units }€</del> { rate.ratePrice }</Tooltip> }€ - <b>Unité</b>: { rate.units }</p>
                              :
                                <p key={`rateItem-${index}`}><b>Tarif</b>: { rate.resumeRate.name.split(" - ").pop() } - <b>Prix</b>: { rate.ratePricePvP }€ - <b>Unité</b>: { rate.units }</p>
                            )
                          }
                        </div>
                        <p><b>Horaire</b>: { activity.optionsTruns.find(o => o.value === activity.formValues.turns).label }</p>
                        {
                          !userData.is_b2b ?
                            <>
                              <p><b>Matériel</b>:</p>
                              <div>
                                <p>
                                  { activity.selectedResources.map((resource, index) =>
                                    resource.value === undefined ?
                                      <>
                                        <Tag key={`resourceItem-${index}`} color="blue" style={{marginTop: 5}}>{activity.optionsResources.find(o => o.value === resource).label.split(':').shift()}</Tag>{ index + 1 < activity.selectedResources.length ? " " : "" }
                                      </>
                                    :
                                      <>
                                        <Tag key={`resourceItem-${index}`} color="blue" style={{marginTop: 5}}>{activity.optionsResources.find(o => o.value === resource.value).label.split(':').shift()}
                                          <b style={{display: "inline-flex", padding: "2px 5px", marginLeft: 8, marginRight: "-7px", background: "#93a9b9", color: "#FFF"}}>{resource.inputValue}<Person style={{fill: "#FFF"}} /></b>
                                        </Tag>{ index + 1 < activity.selectedResources.length ? " " : "" }
                                      </>
                                  )}
                                </p>
                              </div>
                            </>
                          : <p><b>Matériel</b>: { activity.selectedResources.length > 0 ? activity.selectedResources.length : 'Sans matériel' }</p>
                        }
                        
                      </Col>
                    )
                  }
                  </Row>
                </TabPane>
              )
            }
          </Tabs>
        </Col>
        {
          !userData.is_b2b ?
            <Col span={24} className="aditionalServices">
              <Collapse
                bordered={false}
                defaultActiveKey={['1']}
              >
                <Panel header={`Servicios adicionales${ aditionalServices.length > 0 ? ` (${aditionalServices.length})` : "" }`} key="panel-1">
                  <Row>
                    <Col span={24} style={{display: "flex", justifyContent: "center", flexWrap: "wrap"}}>
                      {
                        aditionalServices.length > 0 ?
                        <div className="servicesList">
                          {
                            aditionalServices.map((service, index) =>
                              <div className="serviceItem" key={`aditionalServiceItem-${index}`}>
                                <p><b>Type</b>: { optionTypes.find(o => o.value === service.type).label }</p>
                                <p><b>Description</b>: { service.description }</p>
                                <p><b>Prix</b>: { service.price }€</p>
                                <div className="actionBtns">
                                  {/* <Button
                                    className="iconCircle editBtn"
                                    title="Modifier le service"
                                    onClick={() => editNewService(service, index)}
                                  ><FormOutlined className="white" /></Button> */}
                                  <Button
                                    className="iconCircle deleteBtn"
                                    title="Eliminar servicio"
                                    onClick={() => confirmRemoveService(index)}
                                  ><DeleteOutlined className="white" /></Button>
                                </div>
                              </div>
                            )
                          }
                        </div>
                        : ""
                      }
                      <Button className="main-btn" onClick={() => setIsModalServices(true)}>Ajouter un extra</Button>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Col>
          : <Col span={24} style={{margin: "10px 0"}}></Col>
        }
        <Col span={8} className="priceData">
          <p><b>Total activités</b>: { props.data.reduce((accumulator, object) => {return accumulator + object.activities.length}, 0) }</p>
          <p><b>Prix des activités</b>: { !userData.is_b2b ? sumActivitiesPrice : sumActivitiesPricePvP }€</p>
          <p><b>Prix des extras</b>: { sumServicesPrice }€</p>
          <Divider style={{margin: "10px 0"}} />
          <p className="priceTotal">
            <b>Prix total</b>: <span className={(newTotal !== 0 && sumActivitiesPrice + sumServicesPrice !== newTotal) && !userData.is_b2b ? "changed" : ""}>{ (!userData.is_b2b ? sumActivitiesPrice : sumActivitiesPricePvP) + sumServicesPrice }€</span>
            {
              (newTotal !== 0 && sumActivitiesPrice + sumServicesPrice !== newTotal) && !userData.is_b2b ?
              <>
                {` `}- <span>{ newTotal }€</span>
              </>
              : ""
            }
          </p>
          {/* TEMPORALMENTE COMENTADO */}
          {/* <Button
            className="main-btn"
            style={{position: "absolute", bottom: "12px", right: "10px", left: "unset"}}
            onClick={() => setIsModalTotal(true)}
          >Modifier le prix total</Button> */}
        </Col>
        {
          !userData.is_b2b ?
            <Col span={9} className="paymentData">
              <Form
                form={formPayment}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                onValuesChange={(a, e) => paymentChange(a, e)}
              >
                <Form.Item
                  label="Mode de paiement:"
                  name="method"
                >
                  <Select
                    options={optionsPayment}
                    disabled={optionsPayment.length === 1 ? true : false}
                    placeholder="Choisissez une méthode de paiement"
                  />
                </Form.Item>
                <Form.Item
                  label="Montant:"
                  name="amount"
                  initialValue={0}
                >
                  <InputNumber
                    addonAfter="€"
                    min={0}
                  />
                </Form.Item>
                <Form.Item
                  label="Date de paiement:"
                  name="date"
                  initialValue={dayjs()}
                >
                  <DatePicker
                    allowClear={false}
                    format={'DD-MM-YYYY'}
                  />
                </Form.Item>
                <Row>
                  <Col span={8}></Col>
                  <Col span={16}>
                    {
                      newTotal > 0 && (sumActivitiesPrice + sumServicesPrice) !== newTotal ?
                        (newTotal * (depositChannel / 100)) !== 0 ?
                          <>
                            <Tag color="#53b454" className="simpleTag" style={{marginBottom: 8}}>Cantidad pagada por canal: { newTotal * (depositChannel / 100) }€</Tag>
                            <p><b>Total payé</b>: { (newTotal * (depositChannel / 100)) + totalPaid }€</p>
                            <p><b>Montant dû</b>: { (newTotal - (newTotal * (depositChannel / 100))) - totalPaid < 0 ? 0 : (newTotal - (newTotal * (depositChannel / 100))) - totalPaid }€</p>
                          </>
                        :
                          <>
                            <p><b>Total payé</b>: { totalPaid }€</p>
                            <p><b>Montant dû</b>: { newTotal - totalPaid < 0 ? 0 : newTotal - totalPaid }€</p>
                          </>
                      :
                        ((sumActivitiesPrice + sumServicesPrice) * (depositChannel / 100)) !== 0 ?
                          <>
                            <Tag color="#53b454" className="simpleTag" style={{marginBottom: 8}}>Cantidad pagada por canal: { (sumActivitiesPrice + sumServicesPrice) * (depositChannel / 100) }€</Tag>
                            <p><b>Total payé</b>: { ((sumActivitiesPrice + sumServicesPrice) * (depositChannel / 100)) + totalPaid }€</p>
                            <p><b>Montant dû</b>: { ((sumActivitiesPrice + sumServicesPrice) - ((sumActivitiesPrice + sumServicesPrice) * (depositChannel / 100))) - totalPaid < 0 ? 0 : ((sumActivitiesPrice + sumServicesPrice) - ((sumActivitiesPrice + sumServicesPrice) * (depositChannel / 100))) - totalPaid }€</p>
                          </>
                        :
                          <>
                            <p><b>Total payé</b>: { totalPaid }€</p>
                            <p><b>Montant dû</b>: { (sumActivitiesPrice + sumServicesPrice) - totalPaid < 0 ? 0 : (sumActivitiesPrice + sumServicesPrice) - totalPaid }€</p>
                          </>
                    }
                  </Col>
                </Row>
              </Form>
            </Col>
          : <></>
        }
        <Col span={5} style={{display: "flex", flexDirection: "column", alignItems: "flex-start", gap: 20, justifyContent: "space-between"}}>
          <Badge count={notesList.length}>
            <Button className="main-btn" onClick={() => setIsModalAnnotation(true)}>Notes</Button>
          </Badge>
          <div style={{display: "flex", flexDirection: "column"}}>
            {
              !userData.is_b2b ?
                <div style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: 'column',
                  marginTop: 20,
                  marginBottom: 15,
                  gap: 12
                }}>
                  <Checkbox
                    value={bloqued}
                    checked={bloqued}
                    onChange={(e) => setBloqued(!bloqued)}
                    style={{marginInlineStart: 8}}
                  >Pré-réservation</Checkbox>
                  <Checkbox
                    value={invoice}
                    checked={invoice}
                    onChange={(e) => changeInvoice(e)}
                  >Facture</Checkbox>
                  <Button
                    disabled={!invoice}
                    style={{display: 'flex', gap: 5, paddingInline: '5px 8px', marginLeft: '-2px'}}
                    onClick={() => {setIsModalInvoice(true)}}
                  ><Description />Données de facturation</Button>
                </div>
              : <></>
            }
            <Button className="basic-btn accept-btn" disabled={finishDisable} onClick={() => finishBooking()}>Finaliser la réservation</Button>
          </div>
        </Col>
      </Row>
    </>
  )
}