import React from "react";
import { useEffect, useState, useRef } from "react";
import { Table, Tag, DatePicker, Col, Row, Button, Select, Form, Modal, Divider, Input, Badge, TimePicker } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { CheckCircleTwoTone, CloseCircleTwoTone, LeftOutlined, RightOutlined, SearchOutlined, ContainerFilled, DeleteOutlined, ConsoleSqlOutlined } from "@ant-design/icons";
import styles from "./tablebooking.module.css";
import BookingCreate from "../Reserva/BookingCreate";
import BookingInfo from "../Reserva/BookingInfo";
import Loader from "../Loader/Loader";
import { Lock } from "@mui/icons-material";

import apiManager from "../../api/apiManager";
import "./tablebooking.css";

//confirm dialog
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

export default function TableBooking(props) {

  const today = moment(Date.now()).format('DD-MM-YYYY');
  const dateFormat = 'DD-MM-YYYY';
  const dateSelected = useRef();
  const formRef = useRef();
  const { TextArea } = Input

  useEffect(() => {
    getBookingSimple(today, JSON.parse(localStorage.getItem("activeCenter")).id)
    setChannelList([])
    apiManager.getChannels(JSON.parse(localStorage.getItem("activeCenter")).id)
    .then((res) => {
      res.data.data.map((channel) => {
        setChannelList(current => [...current, {
          'text': channel.title,
          'value': channel.title
        }])
      })
    })
    apiManager.isAdmin(localStorage.getItem("token"))
    .then((res) => {
      setIsAdmin(res.data.data[0].user_is_admin)
    })
    setWeatherList([])
    apiManager.getListWeather(JSON.parse(localStorage.getItem("activeCenter")).id)
    .then((res) => {
      //console.log(res.data.data)
      res.data.data.map((item) => {
        setWeatherList(current => [...current, {
          'label': item.title,
          'value': item.id
        }])
      })
    })
  }, [])

  const [dataTableSimple, setDataTableSimple] = useState([])
  const [dataTableGrouped, setDataTableGrouped] = useState([])
  const [dataTableBase, setDataTableBase] = useState([])
  const [viewTable, setViewTable] = useState('simple')
  const [dateValue, setDateValue] = useState(dayjs(Date.now()).format('DD-MM-YYYY'))
  const [noteDate, setNoteDate] = useState(dayjs(dateValue.split("-").reverse().join("-")))
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalBookingOpen, setIsModalBookingOpen] = useState(false)
  const [isModalAnnotation, setIsModalAnnotation] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [paginationItems, setPaginationItems] = useState(50)
  const [channelList, setChannelList] = useState([])
  const [bookingIdSelected, setBookingIdSelected] = useState({})
  const [isAdmin, setIsAdmin] = useState(false)
  const [notesList, setNotesList] = useState([])
  const [weatherList, setWeatherList] = useState([])
  const [overbookingTurns, setOverbookingTurns] = useState([])
  const [overbookingActivities, setOverbookingActivities] = useState([])
  const [form] = Form.useForm()
  const [formSearch] = Form.useForm()
  const [formNotesDay] = Form.useForm()

  let dateVvalue = dayjs();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getBookingSimple = (date, centerId) => {
    setIsLoading(true)
    getAnotationsDay(date, centerId)
    let turns = []
    let activities = []
    apiManager.getBookingSimple(date, centerId)
    .then((res) => {
      //console.log(res.data.data)
      let result = res.data.data
      setOverbookingTurns([])
      setOverbookingActivities([])
      result.map((booking, index) => {
        if (index < 1) {
          turns.push({
            text: booking.created_hour,
            value: booking.created_hour
          })
          activities.push({
            text: booking.activity,
            value: booking.activity
          })
          setOverbookingTurns(current => [...current, {
            text: booking.created_hour,
            value: booking.created_hour
          }])
          setOverbookingActivities(current => [...current, {
            text: booking.activity,
            value: booking.activity
          }])
        } else {
          if (!turns.some(element => element.value === booking.created_hour)) {
            turns.push({
              text: booking.created_hour,
              value: booking.created_hour
            })
            setOverbookingTurns(current => [...current, {
              text: booking.created_hour,
              value: booking.created_hour
            }])
          }
          if (!activities.some(element => element.value === booking.activity)) {
            activities.push({
              text: booking.activity,
              value: booking.activity
            })
            setOverbookingActivities(current => [...current, {
              text: booking.activity,
              value: booking.activity
            }])
          }
        }
      })
      setDataTableSimple(result)
      setDataTableBase(result)
      setIsLoading(false)
      if (paginationItems !== 50 && paginationItems !== 100 && paginationItems !== 500) {
        if (paginationItems !== result.length) {
          setPaginationItems(50)
          setPaginationItems(result.length)
        }
      }
    })
  }

  const getBookingGrouped = (date, centerId) => {
    setIsLoading(true)
    getAnotationsDay(date, centerId)
    apiManager.getBookingGrouped(date, centerId)
    .then((res) => {
      console.log(res.data.data)
      let result = res.data.data
      setDataTableGrouped(result)
      setDataTableBase(result)
      setIsLoading(false)
    })
  }

  const changeTableDay = (newDate, newDateFormated) => {
    setDateValue(newDateFormated)
    if (viewTable === 'simple') {
      getBookingSimple(newDateFormated, JSON.parse(localStorage.getItem("activeCenter")).id);
      dateVvalue = newDate;
    } else {
      getBookingGrouped(newDateFormated, JSON.parse(localStorage.getItem("activeCenter")).id);
    }
    //console.log(newDateFormated)
  }

  const columnsSimple = [
    {
      title: 'Heure',
      dataIndex: 'created_hour',
      key: 'created_hour',
      sorter: (a, b) => a.created_hour.localeCompare(b.created_hour),
      filters: overbookingTurns,
      onFilter: (value, record) => record.created_hour.indexOf(value) === 0,
    },
    {
      title: 'Activité',
      dataIndex: 'activity',
      key: 'activity',
      filters: overbookingActivities,
      onFilter: (value, record) => record.activity.indexOf(value) === 0,
    },
    {
      title: 'Info. réservation',
      dataIndex: 'client_info',
      key: 'client_info',
    },
    {
      title: 'Email',
      dataIndex: 'client_mail',
      key: 'client_mail',
    },
    {
      title: 'Téléphone',
      dataIndex: 'client_phone',
      key: 'client_phone',
    },
    {
      title: 'Statut',
      dataIndex: 'status',
      key: 'status',
      filters: [
        {
          text: 'Payer',
          value: 'Pagado',
        },
        {
          text: 'Partiellement payer',
          value: 'Parcial',
        },
        {
          text: 'Non payer',
          value: 'No pagado',
        },
        {
          text: 'â rembourser',
          value: 'A devolver',
        },
        {
          text: 'Bloquée',
          value: 'Bloqueada',
        },
        {
          text: 'À crédit',
          value: 'A crédito',
        },
        {
          text: 'Bordereau',
          value: 'Albaran',
        },
        {
          text: 'Refaire bordereau',
          value: 'Realbaranear',
        },
        {
          text: 'Facturée',
          value: 'Facturada',
        }
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (value) => {
        let color = '';
        if (value === 'Pagado') {
          color = 'green';
        } else if (value === 'Parcial') {
          color = 'orange';
        } else if (value === 'No pagado') {
          color = 'red';
        } else if (value === 'A devolver') {
          color = 'blue';
        }
        return (
          <Tag color={color} className={value === 'Bloqueada' ? 'bloqued' : ''}>
            {
              value === 'Bloqueada' ?
                <Lock style={{
                  fill: '#515151',
                  fontSize: 15,
                  marginTop: -3,
                  marginRight: 3
                }} /> : <></>
            }
            {value}
          </Tag>
        );
      }
    },
    {
      title: 'ID',
      dataIndex: 'identifier',
      key: 'identifier'
    },
    {
      title: 'Pers.',
      dataIndex: 'pax',
      key: 'pax',
      align: 'center',
      width: 50
    },
    {
      title: 'Montant',
      dataIndex: 'price',
      key: 'price',
      align: 'right',
      render: (text) => <span>{ Number(text).toFixed(2).replace(/[.,]00$/, "") + "€" }</span>
    },
    {
      title: 'Partenaires',
      dataIndex: 'channel',
      key: 'channel',
      filters: channelList,
      onFilter: (value, record) => record.channel.indexOf(value) === 0,
    },
    /* {
      title: 'Date réservation',
      dataIndex: 'created_date',
      key: 'created_date',
      sorter: (a, b) => a.created_date.localeCompare(b.created_date),
    }, */
    {
      title: 'En activité',
      dataIndex: 'client_arrived',
      key: 'client_arrived',
      align: 'center',
      className: 'arrived',
      render: (value) => value ?
        <CheckCircleTwoTone className={styles.icon} style={{ fontSize: '20px'}} twoToneColor="#52c41a" /> :
        <CloseCircleTwoTone className={styles.icon} style={{ fontSize: '20px'}} twoToneColor="#f56457" />
    },
    {
      title: 'Notes',
      dataIndex: 'annotations',
      key: 'annotations',
      align: 'center',
      className: 'annotations',
      render: (value) => value ?
        <CheckCircleTwoTone className={styles.icon} style={{ fontSize: '20px'}} twoToneColor="#52c41a" /> :
        <CloseCircleTwoTone className={styles.icon} style={{ fontSize: '20px'}} twoToneColor="#f56457" />
    },
  ]

  const columnsGrouped = [
    {
      title: 'Heure',
      dataIndex: 'turn_info',
      key: 'turn_info'
    },
    {
      title: 'Activité',
      dataIndex: 'activity',
      key: 'activity'
    },
    {
      title: 'Places occupées',
      dataIndex: 'bussy_places',
      key: 'bussy_places',
      align: 'right',
    },
    {
      title: 'Places libres',
      dataIndex: 'av_places',
      key: 'av_places',
      align: 'right',
    },
    {
      title: 'Recursos ocupadas',
      dataIndex: 'bussy_resources',
      key: 'bussy_resources',
      align: 'right',
    },
    {
      title: 'Recursos libres',
      dataIndex: 'av_resources',
      key: 'av_resources',
      align: 'right',
    },
    {
      title: 'Total réservations',
      dataIndex: 'num_booking',
      key: 'num_booking',
      align: 'right',
    },
    {
      title: 'Statut',
      dataIndex: 'status',
      key: 'status',
      filters: [
        {
          text: 'Pagado',
          value: 'Pagado',
        },
        {
          text: 'Parcial',
          value: 'Parcial',
        },
        {
          text: 'No pagado',
          value: 'No pagado',
        },
        {
          text: 'A devolver',
          value: 'A devolver',
        },
        {
          text: 'Bloqueada',
          value: 'Bloqueada',
        }
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (value) => {
        let color = '';
        if (value === 'Pagado') {
          color = 'green';
        } else if (value === 'Parcial') {
          color = 'orange';
        } else if (value === 'No pagado') {
          color = 'red';
        } else if (value === 'A devolver') {
          color = 'blue';
        }
        return (
          <Tag color={color} className={value === 'Bloqueada' ? 'bloqued' : ''}>
            {
              value === 'Bloqueada' ?
                <Lock style={{
                  fill: '#515151',
                  fontSize: 15,
                  marginTop: -3,
                  marginRight: 3
                }} /> : <></>
            }
            {value}
          </Tag>
        );
      }
    }
  ]

  const summaryTable = (pageData) => {
    if (viewTable === "simple") {
      if (dataTableSimple.length > 0 && isAdmin) {
        let totalPax = 0;
        let totalPrice = 0;

        pageData.forEach(({ pax, price }) => {
          totalPax += Number(pax);
          totalPrice += Number(price);
        });
        
        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={7}><b>TOTAL</b></Table.Summary.Cell>
              <Table.Summary.Cell align="center"><b>{totalPax}</b></Table.Summary.Cell>
              <Table.Summary.Cell align="right"><b>{ Number(totalPrice).toFixed(2).replace(/[.,]00$/, "") + "€" }</b></Table.Summary.Cell>
              <Table.Summary.Cell colSpan={3}></Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        )
      }
    } else {
      if (dataTableGrouped.length > 0 && isAdmin) {
        let avPlaces = 0;
        let avResources = 0;
        let bussyPlaces = 0;
        let bussyResources = 0;
        let totalBookings = 0;

        pageData.forEach(({ av_places, av_resources, bussy_places, bussy_resources, num_booking }) => {
          avPlaces += av_places === "" ? 0 : av_places;
          avResources += av_resources === "" ? 0 : av_resources;
          bussyPlaces += bussy_places === "" ? 0 : bussy_places;
          bussyResources += bussy_resources === "" ? 0 : bussy_resources;
          totalBookings += num_booking;
        });
        
        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={2}><b>TOTAL</b></Table.Summary.Cell>
              <Table.Summary.Cell align="right"><b>{bussyPlaces}</b></Table.Summary.Cell>
              <Table.Summary.Cell align="right"><b>{avPlaces}</b></Table.Summary.Cell>
              <Table.Summary.Cell align="right"><b>{bussyResources}</b></Table.Summary.Cell>
              <Table.Summary.Cell align="right"><b>{avResources}</b></Table.Summary.Cell>
              <Table.Summary.Cell align="right"><b>{totalBookings}</b></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        )
      }
    }
  }

  const viewChange = (value) => {
    setIsLoading(true)
    if (value === "simple") {
      let fechaNueva = form.getFieldValue('datepicker');
      form.setFieldValue('datepicker', fechaNueva);
      getBookingSimple(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
    } else {
      let fechaNueva = form.getFieldValue('datepicker');
      form.setFieldValue('datepicker', fechaNueva);
      getBookingGrouped(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
    }
    setViewTable(value)
  }

  const onChange = (pagination, filters, sorter, extra) => {
    //console.log('params', pagination, filters, sorter, extra);
    if (pagination.pageSize !== paginationItems) {
      setPaginationItems(pagination.pageSize)
    }
  };

  const confirmCancelResume = (texto) => {
    confirmAlert({
      title: 'Vous êtes sur de vouloir ' + texto + '?',
      message: 'Vous perdrez toutes les données sur les activités.',
      buttons: [
        {
          label: 'Retour',
          onClick: () => closeModal(),
          className: 'close-btn',
        },
        {
          label: 'Annuler',
          className: 'cancel-btn',
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-confirm-suprimir"
    })
  }

  const closeFinishBooking = (date, channel, id) => {
    closeModal()
    let info = {
      id: id,
      channel_id: channel
    }
    let fechaNueva = moment(date.split("-").reverse().join("-"));
    setDateValue(fechaNueva.format('DD-MM-YYYY'))
    form.setFieldValue('datepicker', dayjs(date.split("-").reverse().join("-")));
    if (viewTable === 'simple') {
      getBookingSimple(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
      setBookingIdSelected(info)
      setIsModalBookingOpen(true)
    } else {
      getBookingGrouped(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
      setBookingIdSelected(info)
      setIsModalBookingOpen(true)
    }
    formSearch.setFieldValue("text", "")
  }

  const prevDay = (currentDay) => {
    let fechaNueva = dayjs(currentDay).add(-1, 'day')
    setDateValue(fechaNueva.format('DD-MM-YYYY'))
    form.setFieldValue('datepicker', fechaNueva)
    if (viewTable === 'simple') {
      getBookingSimple(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
    } else {
      getBookingGrouped(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
    }
  }

  const nextDay = (currentDay) => {
    let fechaNueva = dayjs(currentDay).add(1, 'day')
    setDateValue(fechaNueva.format('DD-MM-YYYY'))
    form.setFieldValue('datepicker', fechaNueva)
    if (viewTable === 'simple') {
      getBookingSimple(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
    } else {
      getBookingGrouped(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
    }
  }

  const openBookingInfo = (info, rowIndex, event) => {
    //console.log(info)
    if (event.target.classList.contains('arrived') || (event.target.nodeName === "path" && !event.target.parentElement.parentElement.parentElement.classList.contains('annotations'))) {
      clientArrived(info.booking_activity_id, !info.client_arrived)
    } else {
      setBookingIdSelected(info)
      setIsModalBookingOpen(true)
    }
    /* setBookingIdSelected(info)
    setIsModalBookingOpen(true) */
  }

  const closeBookingInfoModal = () => {
    let fechaNueva = form.getFieldValue('datepicker')
    if (viewTable === 'simple') {
      getBookingSimple(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
    } else {
      getBookingGrouped(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
    }
    formSearch.setFieldValue("text", "")
    setIsModalBookingOpen(false)
  }

  const clientArrived = (id, newState) => {
    let data = {
      booking_activity_id: id,
      status: newState
    }
    apiManager.clientArrived(data)
    .then((res) => {
      console.log(res.data.data)
      let fechaNueva = form.getFieldValue('datepicker')
      if (viewTable === 'simple') {
        getBookingSimple(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
      } else {
        getBookingGrouped(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
      }
    })
  }

  const searchOnTable = (text) => {
    console.log(text)
    if (text.text !== undefined && text.text !== "") {
      //let tableData = viewTable === 'simple' ? dataTableSimple : dataTableGrouped
      let tableData = dataTableBase
      const filterResult = tableData.filter(
        (item) =>
          item.client_info
            .toLowerCase().includes(text.text.toLowerCase()) ||
          item.identifier
            .toLowerCase().includes(text.text.toLowerCase()) ||
          item.status.toLowerCase().includes(text.text.toLowerCase()) ||
          item.channel.toLowerCase().includes(text.text.toLowerCase()) ||
          item.activity.toLowerCase().includes(text.text.toLowerCase())
      );
      if (viewTable === 'simple') {
        setDataTableSimple(filterResult)
      } else {
        setDataTableGrouped(filterResult)
      }
    } else {
      console.log(dataTableBase)
      if (viewTable === 'simple') {
        setDataTableSimple(dataTableBase)
      } else {
        setDataTableGrouped(dataTableBase)
      }
    }
  }

  const closeAnnotationModal = () => {
    setIsModalAnnotation(false)
    setIsLoading(true)
    if (viewTable === "simple") {
      let fechaNueva = form.getFieldValue('datepicker');
      form.setFieldValue('datepicker', fechaNueva);
      getBookingSimple(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
    } else {
      let fechaNueva = form.getFieldValue('datepicker');
      form.setFieldValue('datepicker', fechaNueva);
      getBookingGrouped(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id)
    }
    formNotesDay.resetFields()
  }

  const getAnotationsDay = (date, centerId) => {
    apiManager.getAnotationsDay(date, centerId)
    .then((res) => {
      //console.log(res.data.data)
      setNotesList(res.data.data)
    })
  }

  const addNoteDay = (values) => {
    console.log(dateValue)
    let data = {
      annotation: values.note,
      weather: values.weather === undefined ? "" : values.weather,
      date: values.date.format('DD-MM-YYYY') + " " + values.time.format('HH:mm')
    }
    apiManager.addAnotationDay(data)
    .then((res) => {
      if (viewTable === 'simple') {
        getBookingSimple(dateValue, JSON.parse(localStorage.getItem("activeCenter")).id)
      } else {
        getBookingGrouped(dateValue, JSON.parse(localStorage.getItem("activeCenter")).id)
      }
      formNotesDay.resetFields()
    })
    console.log(data)
  }

  const deleteNoteDay = (id) => {
    let data = {
      dateddannotation_id: id
    }
    apiManager.deleteAnotationDay(data)
    .then((res) => {
      if (viewTable === 'simple') {
        getBookingSimple(dateValue, JSON.parse(localStorage.getItem("activeCenter")).id)
      } else {
        getBookingGrouped(dateValue, JSON.parse(localStorage.getItem("activeCenter")).id)
      }
    })
  }

  const confirmDeleteNote = (id) => {
    confirmAlert({
      title: '¿Seguro que quieres eliminar esta anotación?',
      buttons: [
        {
          label: 'Annuler',
          className: 'cancel-btn',
        },
        {
          label: 'Confirmer',
          onClick: () => deleteNoteDay(id),
          className: 'confirm-btn',
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-confirm-suprimir"
    })
  }

  const openModalAnotationDay = () => {
    //let todayTime = dayjs().format('HH:MM')
    formNotesDay.setFieldValue('date', dayjs(dateValue.split("-").reverse().join("-")))
    formNotesDay.setFieldValue('time', dayjs())
    setIsModalAnnotation(true)
  }

  const onSelectTimeAnotation = (value) => {
    formNotesDay.setFieldValue('time', value)
  }

  return (
    <>
      <Loader controller={isLoading} />
      <Modal
        maskClosable={false}
        //open={isModalOpen}
        //onOk={() => closeModal()}
        onCancel={() => confirmCancelResume('cerrar la ventana')}
        centered={true}
        open={isModalOpen}
        width={1140}
        destroyOnClose={true}
        cancelText="Annuler réservation"
        footer={null}
      >
        <BookingCreate
          closeFinishBooking={closeFinishBooking}
        />
        <Divider />
        <Row justify="end">
          <Col>
            <Button
              onClick={() => confirmCancelResume('cancelar la reserva')}
              className="basic-btn"
            >Annuler réservation</Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        maskClosable={false}
        onCancel={() => closeBookingInfoModal()}
        centered={true}
        open={isModalBookingOpen}
        width={1000}
        destroyOnClose={true}
        cancelText="Retour"
        footer={null}
      >
        <BookingInfo
          bookingInfoData={bookingIdSelected}
          bookingClose={closeBookingInfoModal}
          selectedDate={dateValue}
        />
      </Modal>
      <Modal
        footer={null}
        open={isModalAnnotation}
        centered={true}
        maskClosable={false}
        onCancel={() => closeAnnotationModal()}
        width={700}
        destroyOnClose={true}
      >
        {
          notesList.length > 0 ?
            <>
              <div className="listNotes">
                {
                  notesList.map((note, index) =>
                    <div className="noteItem" key={`noteKey-${note.id}`}>
                      <Row align="middle">
                        <Col span={22}>
                          <p><b>Usuario</b>: { note.user_mail }</p>
                          <p><b>Date de l´annotation</b>: { note.created_date }</p>
                          <p><b>Clima</b>: { note.weather }</p>
                          <p><b>Anotación</b>: { note.annotation }</p>
                        </Col>
                        <Col span={2} style={{textAlign: "right"}}>
                          <Button
                            className="iconCircle deleteBtn"
                            title="Supprimer l´annotation"
                            onClick={() => confirmDeleteNote(note.id)}
                          ><DeleteOutlined className="white" /></Button>
                        </Col>
                      </Row>
                    </div>
                  )
                }
              </div>
              <Divider />
            </>
          : "" 
        }
        <Form
          form={formNotesDay}
          onFinish={() => addNoteDay(formNotesDay.getFieldsValue())}
          initialValues={{date: dayjs(dateValue.split("-").reverse().join("-")), time: dayjs()}}
          style={notesList.length < 1 ? {marginTop: 30} : {}}
        >
          <Row>
            <Col span={12}>
              <Form.Item
                label="Clima"
                name="weather"
                labelCol={{span: 8}}
              >
                <Select
                  style={{ width: 120 }}
                  options={weatherList}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={12} style={{display: "flex"}}>
              <Form.Item
                label="Fecha"
                name="date"
                rules={[
                  {
                    required: true,
                    message: "Champ obligatoire"
                  },
                ]}
                wrapperCol={{offset: 0, span: 15}}
                //initialValue={dayjs(dateValue.split("-").reverse().join("-"))}
              >
                <DatePicker
                  allowClear={false}
                  //defaultValue={dayjs()}
                  //value={dayjs(dateValue.split("-").reverse().join("-"))}
                  //onChange={(e) => changeNoteDate(e)}
                  format={dateFormat}
                />
              </Form.Item>
              <Form.Item
                name="time"
                rules={[
                  {
                    required: true,
                    message: "Champ obligatoire"
                  },
                ]}
                labelCol={{offset: 0, span: 0}}
                wrapperCol={{offset: 0, span: 15}}
                //initialValue={dayjs(dateValue.split("-").reverse().join("-"))}
              >
                <TimePicker
                  allowClear={false}
                  format={"HH:mm"}
                  onSelect={(e) => onSelectTimeAnotation(e)}
                  //defaultValue={dayjs(dayjs(Date.now()).format('HH:mm'), 'HH:mm')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Notes"
            name="note"
            style={{marginTop: 8}}
            labelCol={{span: 4}}
            rules={[
              {
                required: true,
                message: "Champ obligatoire"
              },
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item style={{display: "flex", justifyContent: "center"}}>
            <Button
              className="basic-btn"
              style={{marginRight: 20}}
              onClick={() => closeAnnotationModal()}
            >Retour</Button>
            <Button
              className="main-btn"
              htmlType="submit"
            >Enregistrer commentaire</Button>
          </Form.Item>
        </Form>
      </Modal>
      <Row
        justify={'space-between'}
        className={styles.header}
      >
        <Col span={8} style={{display: "flex", justifyContent: "left", alignItems: "center"}}>
        <Select
          defaultValue="simple"
          style={{ width: 120 }}
          onChange={viewChange}
          options={[
            {
              value: 'simple',
              label: 'Vue simple',
            },
            {
              value: 'grouped',
              label: 'Vue groupée',
            }
          ]}
        />
        <Badge count={notesList.length}>
          <Button
            className="main-btn"
            icon={<ContainerFilled />}
            onClick={() => openModalAnotationDay()}
            style={{marginLeft: 15}}
          >
            Notes
          </Button>
        </Badge>
        </Col>
        <Col span={8} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Form
            form={form}
            ref={formRef}
            initialValues={{datepicker: dayjs()}}
            style={{display: "flex", gap: 10}}
          >
            <Button onClick={() => prevDay(form.getFieldValue('datepicker'))}><LeftOutlined /></Button>
            <Form.Item
              name="datepicker"
              style={{marginBottom: 0}}
            >
              <DatePicker
                allowClear={false}
                //defaultValue={dayjs()}
                format={dateFormat}
                ref={dateSelected}
                className="datapickerElement"
                //value={setDateValue}
                //bordered={false}
                onChange={(newDate, newDateFormated) => changeTableDay(newDate, newDateFormated)}
              />
            </Form.Item>
            <Button onClick={() => nextDay(form.getFieldValue('datepicker'))} style={{marginLeft: "-5px"}}><RightOutlined /></Button>
          </Form>
        </Col>
        <Col span={8} style={{display: "flex", justifyContent: "right", alignItems: "center"}}>
          <Form
            className="formSerachBooking"
            form={formSearch}
            onFinish={(values) => searchOnTable(values)}
            style={{marginRight: 20}}
          >
            <Form.Item
              name="text"
              style={{marginBottom: 0}}
            >
              <Input
                placeholder="Recherche..."
              />
            </Form.Item>
            <Form.Item
              style={{marginBottom: 0}}
            >
              <Button
                className="iconCircle"
                htmlType="submit"
              ><SearchOutlined className="white" /></Button>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            shape="round"
            size='large'
            className={styles.button}
            onClick={() => showModal()}
            style={{marginRight: 6}}
          >
            <p><span>+</span>Réservation</p>
          </Button>
        </Col>
      </Row>
      <Table
        columns={viewTable === 'simple' ? columnsSimple : columnsGrouped}
        dataSource={viewTable === 'simple' ? dataTableSimple : dataTableGrouped} 
        bordered
        onChange={onChange}
        className="mainTable"
        pagination={
          {
            total: dataTableSimple.length,
            pageSize: paginationItems,
            //defaultPageSize: 50,
            showSizeChanger: true,
            //onChange: (e) => console.log(e),
            pageSizeOptions: ['50', '100', '500', dataTableSimple.length],
            locale: { items_per_page: ""},
            showTotal: (total) => `${total} ${total < 2 ? "activité" : "activités"} aujourd´hui`,
          }
        }
        summary={pageData => summaryTable(pageData)}
        onRow={(record, rowIndex) =>
          viewTable === 'simple' ?
            {
              onClick: event => openBookingInfo(record, rowIndex, event)
            }
            //onClick: event => openBookingInfo(record),
          : ""
        }
      />
    </>
  )
}