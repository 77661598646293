import React, { useState, useEffect } from "react";
import { DatePicker, Row, Col, Tabs, Form, Select, Empty } from "antd";
import dayjs from "dayjs";
import apiManager from "../../api/apiManager";
import TableClosedCash from "../Tabla/TableClosedCash";
import Loader from "../Loader/Loader";

//confirm dialog
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

export default function ClosedCashComp(props) {

  const { RangePicker } = DatePicker;
  const [dataTable, setDataTable] = useState([])
  const [tableKey, setTableKey] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState([])
  const [search, setSearch] = useState(false)
  const [form] = Form.useForm()

  useEffect(() => {
    apiManager.getPaymentMethods(null, JSON.parse(localStorage.getItem("activeCenter")).id)
    .then((res) => {
      setPaymentMethods([])
      let result = res.data.data
      result.map((method) => {
        setPaymentMethods(current => [...current, {
          'value': method.id,
          'label': method.title
        }])
      })
    })
  }, [])

  const changeDate = (value) => {
    if (form.getFieldValue('payment') !== undefined) {
      setIsLoading(true)
      let startDate = dayjs(value[0]).format("DD-MM-YYYY")
      let endDate = dayjs(value[1]).format("DD-MM-YYYY")
      let payment = form.getFieldValue('payment')
      apiManager.bookingPaymentByCenter(JSON.parse(localStorage.getItem("activeCenter")).id, startDate, endDate, payment)
      .then((res) => {
        let result = res.data.data
        setDataTable(result)
        setTableKey(tableKey + 1)
        setSearch(true)
        setIsLoading(false)
      })
    }
  }

  const changePayment = (value) => {
    if (form.getFieldValue('date') !== undefined) {
      setIsLoading(true)
      let startDate = dayjs(form.getFieldValue('date')[0]).format("DD-MM-YYYY")
      let endDate = dayjs(form.getFieldValue('date')[1]).format("DD-MM-YYYY")
      let payment = value
      apiManager.bookingPaymentByCenter(JSON.parse(localStorage.getItem("activeCenter")).id, startDate, endDate, payment)
      .then((res) => {
        let result = res.data.data
        console.log(result)
        setDataTable(result)
        setTableKey(tableKey + 1)
        setSearch(true)
        setIsLoading(false)
      })
    }
  }

  return (
    <>
      <Loader controller={isLoading} />
      <Row>
        <Col span={24} style={{height: 70}}>
          <Form
            form={form}
            style={{display: "flex", gap: 20}}
          >
            <Form.Item
              name="date"
            >
              <RangePicker format="DD/MM/YYYY" allowClear={false} onChange={(e) => changeDate(e)} />
            </Form.Item>
            <Form.Item
              name="payment"
            >
              <Select
                options={paymentMethods}
                onChange={(e) => changePayment(e)}
                style={{width: 220}}
                placeholder="Choisissez une méthode de paiement"
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row>
        { dataTable.length > 0 ?
          <Tabs
            defaultActiveKey="1"
            centered
            //onChange={(e) => changeTab(e)}
            style={{width: "100%"}}
            items={[
              {
                label: "Tabla",
                key: "1",
                children:
                  <Col span={24}>
                    <TableClosedCash
                      key={tableKey}
                      data={dataTable}
                    />
                  </Col>
              },
              /* {
                label: "Graficas",
                key: "2",
                children:
                  <Col span={24}>
                    <MensualProductionGraph
                      key={tableKey}
                      data={dataTable}
                    />
                  </Col>
              } */
            ]}
          />
        : 
          <Empty
            className="emptyBoxTable"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{
              width: "100%",
              background: "#FFF",
              display: "grid",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              padding: "100px 0",
            }}
            description={
              search ?
                <span>Pas de données pour cette recherche</span>
              : <span>Sélectionnez une période et<br />un mode de paiement</span>
            }
          />
        }
      </Row>
    </>
  )
}