/* eslint-disable */

import React, { useState } from "react";

//UI
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import styles from "./formularioReserva.module.css";

//Components
import DatosPersonales from "./DatosPersonales";
import Actividad from "./Actividad.js";
import ResumenReserva from "./ResumenReserva";
import ModalMed from "../UI/ModalMed";

//Calendario
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useRef } from "react";

//confirm dialog
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

const localizer = momentLocalizer(moment);
require("moment/locale/es.js");

function FormularioReserva(props) {
  console.log(props)
  //modals
  const [modalMedShowInfoReserva, setModalMedShowInfoReserva] = useState(false);

  //get datos personales from child
  const passData = (data) => {
    setDatosPersonalesData(data);
  };

  const [datosPersonalesData, setDatosPersonalesData] = useState([]);
  localStorage.setItem(
    "canalID",
    JSON.stringify(datosPersonalesData.channel_id)
  );

  //get datos actividad from child
  const [actividadSeleccionadaData, setActividadSeleccionadaData] = useState(
    []
  );

  const setDato = (data) => {
    if (data != undefined) {
      setActividadSeleccionadaData(data);
    }
  };

  //disabled
  const [disabled, setDisabled] = useState(true);
  const passDisableStatus = (data) => {
    setDisabled(data);
  };

  //********* Buttons
  const [continuarBtn, setContinuarBtn] = useState("");

  const [modalMedShow, setShowModal] = useState("");

  const [crearBorrador, setCrearBorrador] = useState("");
  const [backBtn, setBackBtn] = useState("");

  const [seleccionActividad, setShowSeleccionActividad] = useState("");

  let [selectedDate, setSelectedDate] = useState([]);

  const [events, setEvents] = useState([])

  const [disableCalendario, setDisableCalendario] = useState(false);
  // const [disabledActividadSection, setDisableActividadSection] = useState(
  //   false
  // );
  // console.log(disabledActividadSection, "disabled ?????");

  const childFunc = useRef();

  const [reservaActividades, setReservaActividades] = useState([]);

  const actividadesUpdate = (datos) => {
    if (datos.length < 1) {
      setContinuarBtn("");
    } else {
      setContinuarBtn(
        <Button
          // variant="primary"
          type="button"
          className="btn btn-secondary"
          onClick={(e) => continuarAResumenDeReserva(e)}
        >
          Siguiente
        </Button>
      );
    }
    setReservaActividades(datos)
  }

  //selector fecha y pasar fecha a actividad
  const handleSelect = (event, e) => {
    setShowCalendario(calendar);
    const { start, end } = event;
    const data = { title: "hi", start, end, allDay: false };
    let fecha = selectedDate.push(data);

    setEvents([{
      title: '',
      start: start,
      end: end,
      allDay: true
    }])

    setSelectedDate(fecha);
    setShowSeleccionActividad(
      <Actividad
        continuaraResumen={continuarAResumenDeReserva}
        setShowCalendario={backToActividad}
        fecha={event}
        passDatosActividad={setDato}
        canalId={datosPersonalesData.channel_id}
        actividadesUpdate={actividadesUpdate}
      />
    );

    /* setBackBtn(
      <Button
        // variant="primary"
        type="button"
        className={`btn btn-secondary ${styles.backBtn}`}
        onClick={(e) => backToActividad(e)}
      >
        Volver atrás
      </Button>
    ); */

    /* setContinuarBtn(
      <Button
        // variant="primary"
        type="button"
        className="btn btn-secondary"
        onClick={(e) => continuarAResumenDeReserva(e)}
      >
        Siguiente
      </Button>
    ); */
    setDisableCalendario(true);
  };

  //dialog en desarrollo
  const wipDialog = () => {
    confirmAlert({
      title: 'Funcionalidad en desarrollo',
      buttons: [
        {
          label: 'Volver',
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-underconstruction"
    })
  }

  //Calendario
  const calendar = (
    <div className={`${styles.calendarDisabled}`}>
      <div style={{ height: "300px" }} className={`bigCalendar-container `}>
        <Calendar
          selectable
          localizer={localizer}
          startAccessor="start"
          endAccessor="end"
          messages={{
            next: ">",
            today: "Mois en cours",
            previous: "<",
            month: "Mes",
            week: "Semana",
            day: "Día",
          }}
          view="month"
          onView={() => console.log("viewd")}
          views={["month"]}
          events={events}
          onSelectSlot={handleSelect}
        />
      </div>
    </div>
  );

  const test = () => {
    setShowSeleccionActividad("");
    setDisableCalendario(false);
  };

  //***********Show componente proceso reservas
  const [datosPersonales, setShowDatosPersonales] = useState(
    props.title === "añadir actividad" ? (
      ""
    ) : (
      <DatosPersonales
        restartFiltro={test}
        passPersonalData={passData}
        passDisabledInfo={passDisableStatus}
        //childFunc={childFunc}
        ref={childFunc}
      />
    )
  );

  const [calendario, setShowCalendario] = useState(calendar);

  //Resumen de la reserva
  const [showResumenReserva, setShowResumenReserva] = useState(false);

  //hide Componentes/botones inecesarios para el siguiente paso continuar al siguiente paso

  const continuarAResumenDeReserva = (e) => {
    e.preventDefault();
    setShowSeleccionActividad("");
    setShowDatosPersonales("");
    setShowCalendario("");
    setShowResumenReserva(true);
    setCrearBorrador(
      <Button
        variant="primary"
        type="button"
        className={`btn btn-secondary ${styles.crearBorradorBtn}`}
        //onClick={() => setShowModal(true)}
        onClick={() => wipDialog()}
      >
        Enregistrer le brouillon
      </Button>
    );

    setContinuarBtn("");
  };

  const backToActividad = (e) => {
    e.preventDefault();
    setShowSeleccionActividad(seleccionActividad);
    setShowDatosPersonales(datosPersonales);
    setShowCalendario(calendario);
    setShowResumenReserva("");
    setCrearBorrador("");

    setContinuarBtn(
      <Button
        // variant="primary"
        type="button"
        className="btn btn-secondary"
        onClick={(e) => continuarAResumenDeReserva(e)}
      >
        Continuar
      </Button>
    );
  };

  // let [isDateselected, setIsDateSelected] = useState(false);
  let isDateselected = false;

  //borrar selector actividades y volver a seleccionar fecha

  const [
    modalMedShowSeleccionarFechaNueva,
    setModalMedShowSeleccionarFechaNueva,
  ] = useState(false);

  let [warningNewDate, setShowWarningNewDate] = useState(false);

  const seleccionarFechaNueva = (e) => {
    e.preventDefault();
    setShowSeleccionActividad("");
    setShowWarningNewDate(false);
    setDisableCalendario(false);
  };

  const mantenerFechaActual = (e) => {
    e.preventDefault();
    setShowWarningNewDate(false);
  };

  const closeReserva = (estado) => {
    props.completeReserva(estado)
  }

  const updateDatosPersonales = (type, value) => {
    console.log(type + ": " + value)
  }

  return (
    <div className={styles.formContainer}>
      <ModalMed
        show={modalMedShow}
        onHide={() => setShowModal(false)}
        title="reservaBorrador"
      />
      <ModalMed
        show={modalMedShowInfoReserva}
        onHide={() => setModalMedShowInfoReserva(false)}
        title="infoReserva"
      />
      <ModalMed
        show={modalMedShowSeleccionarFechaNueva}
        onHide={() => setModalMedShowSeleccionarFechaNueva(false)}
        title="seleccionarFechaNueva"
      />

      <Form>
        {/* components to get data from customer/time of activity/and type of activity  */}
        <div className={styles.formContent}>
          <div className={styles.One}>{datosPersonales}</div>
          <div
            className={
              props.title === "añadir actividad" ? styles.fullWidth : styles.Two
            }
          >
            <div
              isDateSelected={false}
              onClick={(e) => {
                const isDateselected = e.currentTarget.getAttribute(
                  "isDateSelected"
                );

                console.log(disableCalendario, "1");

                if (isDateselected === false) {
                  if (disableCalendario === true) {
                    // setIsDateSelected(true);

                    e.currentTarget.setAttribute("isDateSelected", true);
                  }
                } else {
                  console.log(disableCalendario, "2");
                  if (disableCalendario === true) {
                    // setModalMedShowSeleccionarFechaNueva(true);
                    setShowWarningNewDate(true);
              
                  }
                }
                //validateDatosPer(disableCalendario)
                childFunc.current.childFunction(disabled)
              }}
            >
              <div
                className={
                  disableCalendario
                    ? styles.calendarioTopLayer
                    : disabled
                    ? styles.calendarioTopLayer
                    : ""
                }
              >
                {calendario}
              </div>
            </div>
          </div>
        </div>
        {warningNewDate ? (
          <div className={styles.warningNewDateSelection}>
            <div>
              <p>
                Si vous souhaitez sélectionner une nouvelle date, vous perdrez les informations
              </p>
              <div className={styles.warningNewDateBtnContainer}>
                <button
                  className="btn btn-success"
                  onClick={(e) => seleccionarFechaNueva(e)}
                >
                  Nouvelles dates
                </button>
                <button
                  className="btn btn-warning"
                  onClick={(e) => mantenerFechaActual(e)}
                >
                  Conserver la date actuelle
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div>{seleccionActividad}</div>

        {showResumenReserva ? (
          <ResumenReserva
            datosPersonales={datosPersonalesData}
            datosActividad={actividadSeleccionadaData}
            closeReserva={closeReserva}
            updateDatosPersonales={updateDatosPersonales}
          />
        ) : (
          ""
        )}

        <div className={styles.formBtnsContainer}>
          {backBtn}
          {continuarBtn}
          {crearBorrador}
        </div>
      </Form>
    </div>
  );
}

export default FormularioReserva;
